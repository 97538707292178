<template>
	<div>
		<v-layout class="min-height-57px">
			<v-flex class="font-level-3-bold my-auto d-flex justify-space-between w-100">
				<span class="detail-svg-icon mr-2 d-flex align-center">
					<inline-svg	
						fill="#006fc8"
						:src="$assetURL('media/custom-svg/quotation3.svg')"
						height="20"
						width="20"
					/>
					<span class="ml-2">Project Transfers </span>
				</span>
			</v-flex>	
		</v-layout>
		<div
			v-if="!pageLoading"
			class="overflow-y taskListing"
			style="max-height: calc(100vh - 400px); min-height: 350px"
		>
			<v-simple-table class="bt-table listing-table table-head-sticky">
				<thead style="background-color: #f7fafc">
					<tr>
						<th>#</th>
						<th>Project Transfer #</th>
						<th>Project From</th>
                        <th>Project To</th>
						<th>Created AT</th>
					</tr>
				</thead>
				
				<tbody v-if="purchaseOrderListing.length">
					
					<tr
						@click="goToPODetail(row)"
						class="cursor-pointer"
						v-for="(row, index) in purchaseOrderListing"
						:key="index"
					>
						<td class="td_column cursor-default simple-table-td">
							{{ index + 1 }}
						</td>
						<td class="td_column cursor-default simple-table-td">
							<div class="d-flex align-content-start flex-wrap align-start flex-column my-1">
								<div>
									<Chip
										tooltip
										tooltip-text="Project transfer #"
										small
										:text="row.barcode"
										color="blue darken-4"
									></Chip>
								</div>
								<div>
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<v-chip
												v-bind="attrs"
												v-on="on"
												outlined
												label
												tooltip
												style="text-transform: uppercase"
												class="mt-1"
												:color="getStatusColor(row.status)"
												>{{ row.status }}</v-chip
											>
										</template>
										<span> Project Transfer Status </span>
									</v-tooltip>
								</div>
							</div>
						</td>
						
						<td>
							<div>
                                <Chip
                                    tooltip
                                    tooltip-text="from"
                                    small
                                    :text="row.project_from_barcode"
                                    color="blue darken-4"
                                ></Chip>
							</div>
						</td>
                        <td>
							<div>
								<Chip
                                    tooltip
                                    tooltip-text="to"
                                    small
                                    :text="row.project_to_barcode"
                                    color="blue darken-4"
                                ></Chip>
							</div>
						</td>
						<td class="td_column cursor-default simple-table-td">
							<div>
								<Chip
									tooltip
									tooltip-text="Created By"
									small
									:text="row.added_by"
									color="blue darken-4 white--text"
									class="mb-1"
								></Chip>
							</div>
							<TableDateTime
								small
								v-if="row.created_at"
								:human-format="row.added_at"
								:system-format="row.created_at"
							></TableDateTime>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="11">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no project transfers at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</v-simple-table>
		</div>
		<div v-else class="mx-20 my-40 text-center">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>

		<!-- <DeleteTemplate
			v-if="deleteDialog"
			type="Quotation"
			v-on:success="deleteQuotation"
			v-on:close="(deleteDialog = false), (uuid = null)"
			:delete-dialog="deleteDialog"
			:deleteText="singleQuotation.title"
			:deleteByUrl="false"
		>
		</DeleteTemplate> -->
	</div>
</template>
<script>
//import ShowValue from "@/view/components/ShowValue.vue";

// import DeleteTemplate from "@/view/components/DeleteTemplate.vue";
import Chip from "@/view/components/Chip";
import { SET_ERROR } from "@/core/services/store/common.module";
import { getProjectTransfersByProject } from "@/core/lib/project.lib";
import TableDateTime from "@/view/components/TableDateTime";

export default {
	name: "asset-internal-listing",
	title: "Listing Internal Asset",
	components: {
		//ShowValue,
		Chip,
		// DeleteTemplate,
		TableDateTime,
		//ShowPrice,
	},
	data() {
		return {
			deleteDialog: false,
			pageLoading: false,
			quotation_analysis: null,
			uuid: null,
			singleQuotation: null,
			purchaseOrderListing: [],
		};
	},
	props: {
		relationTypeId: {
			type: Number,
			default: 0,
		},
		project: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		openDeleteConfirmationDialog(row) {
			this.deleteDialog = true;
			this.singleQuotation = row;
			this.uuid = row.uuid;
		},
		// async deleteQuotation() {
		// 	this.deleteDialog = false;
		// 	try {
		// 		this.pageLoading = true;
		// 		await deleteQuotation(this.uuid);
		// 		this.uuid = null;
		// 		this.$store.commit(SET_MESSAGE, [
		// 			{ model: true, message: `Success ! Quotation has been deleted.` },
		// 		]);
		// 		await this.getQuotationList();
		// 	} catch (error) {
		// 		this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
		// 	} finally {
		// 		this.pageLoading = false;
		// 	}
		// },

		goToQuotationCreate() {
			this.$router
				.push({
					name: "quotation-create",
					query: { project: this.$props.project.id, customer: this.$props.project.contact },
				})
				.catch(() => {});
		},
		goToPODetail(row) {
			//console.log(row.uuid);
			this.$router.push({ name: "project_transfer_details", params: { uuid: row.uuid } });
		},
		goToQuotationDetail(quotation) {
			this.$router
				.push({
					name: "quotation-detail",
					params: { uuid: quotation.uuid },
				})
				.catch(() => {});
		},
		async getQuotationList() {
			try {
				this.pageLoading = true;
				const data = await getProjectTransfersByProject(this.$route.params.uuid);
				
				this.purchaseOrderListing = data;
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
	},	
	async mounted() {
		this.internal = true;
		await this.getQuotationList();
	},
};
</script>
