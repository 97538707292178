<template>
	<div>
		<v-layout class="min-height-57px">
			<v-flex class="font-level-3-bold my-auto d-flex justify-space-between w-100">
				<span class="detail-svg-icon mr-2 d-flex align-center">
					<inline-svg	
						fill="#006fc8"
						:src="$assetURL('media/custom-svg/quotation3.svg')"
						height="20"
						width="20"
					/>
					<span class="ml-2">Purchase Orders </span>
				</span>
			</v-flex>	
		</v-layout>
		<div
			v-if="!pageLoading"
			class="overflow-y taskListing"
			style="max-height: calc(100vh - 400px); min-height: 350px"
		>
			<v-simple-table class="bt-table listing-table table-head-sticky">
				<thead style="background-color: #f7fafc">
					<tr>
						<th>#</th>
						<th>Purchase Order #</th>
						<th>Details</th>
						<th>Customer</th>
						<th>Supplier</th>
						<!-- <th >Contact Person</th>
						<th >Billing Address</th> -->
						<!-- <th>Project</th> -->
						<th>Created AT</th>
					</tr>
				</thead>
				
				<tbody v-if="purchaseOrderListing.length">
					<!-- /purchase-order/dbe819a7-b718-4a34-984c-40a955727ae4?t=1686291707338&tab=detail -->

					<tr
						@click="goToPODetail(row)"
						class="cursor-pointer"
						v-for="(row, index) in purchaseOrderListing"
						:key="index"
					>
						<td class="td_column cursor-default simple-table-td">
							{{ index + 1 }}
						</td>
						<td class="td_column cursor-default simple-table-td">
							<div class="d-flex align-content-start flex-wrap align-start flex-column my-1">
								<div>
									<Chip
										tooltip
										tooltip-text="purchase-order #"
										small
										:text="row.barcode"
										color="blue darken-4"
									></Chip>
								</div>
								<div>
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<v-chip
												v-bind="attrs"
												v-on="on"
												outlined
												label
												tooltip
												style="text-transform: uppercase"
												class="mt-1"
												:color="getStatusColor(row.status_value)"
												>{{ row.status_value }}</v-chip
											>
										</template>
										<span> Purchase Order Status </span>
									</v-tooltip>
								</div>
							</div>
						</td>
						<!-- <td>
							<v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<v-btn depressed fab dark x-small color="green" v-bind="attrs" v-on="on" class="mr-3">
										<v-icon>mdi-pencil</v-icon>
									</v-btn>
								</template>
								<span>Edit Project</span>
							</v-tooltip>
							<v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<v-btn depressed fab dark x-small color="red" v-bind="attrs" v-on="on" class="mr-3">
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</template>
								<span>Delete Project</span>
							</v-tooltip>
						</td> -->
						<td class="td_column cursor-default simple-table-td">
							<div>
								<span class="fw-500">Ref : </span>
								<ShowValue :object="row" object-key="reference_number" label="Reference number"></ShowValue>
							</div>
							<div>
								<span class="fw-500">Amount : </span>
								<ShowPrice :object="row" object-key="total" label="amount"></ShowPrice>
							</div>
							<div>
								<span class="fw-500">Delivery Date : </span>
								<template v-if="row.date">
									{{ formatDate(row.date) }}
								</template>
								<template v-else>
									<em class="text-muted">no date</em>
								</template>
							</div>
						</td>
						<td class="td_column cursor-default simple-table-td">
							<div>
								<v-icon small left>mdi-account</v-icon>
								<ShowValue
									tooltip
									tooltip-text="supplier company name"
									:object="row"
									object-key="supplier_company_name"
									label="supplier company name"
								></ShowValue>
							</div>
							<div>
								<v-icon small left>mdi-home-city-outline</v-icon>
								<ShowValue
									tooltip
									tooltip-text="supplier name"
									:object="row"
									object-key="supplier_name"
									label="supplier Name"
								></ShowValue>
							</div>
							<div>
								<v-icon small left>mdi-email</v-icon>
								<ShowValue
									tooltip
									click-to-copy
									tooltip-text="supplier email"
									:object="row"
									object-key="supplier_email"
									label="supplier email"
								></ShowValue>
							</div>
							<div>
								<v-icon small left>mdi-phone</v-icon>
								<ShowValue
									tooltip
									click-to-copy
									tooltip-text="supplier Phone"
									:object="row"
									object-key="supplier_phone"
									label="supplier Phone"
								>
								</ShowValue>
							</div>
						</td>
						<td v-if="false">
							<div>
								<span class="fw-500">Name : </span>
								<ShowValue :object="row" object-key="project_name" label="project name"></ShowValue>
							</div>
							<div>
								<span class="fw-500">Price : </span>
								<ShowPrice
									class="fw-600"
									:object="row"
									object-key="project_price"
									label="price"
								></ShowPrice>
							</div>
							<div>
								<span class="fw-500">Start : </span>
								<template v-if="row.project_start_date">
									{{ formatDate(row.project_start_date) }}
								</template>
								<template v-else>
									<em class="text-muted">no start date</em>
								</template>
							</div>
							<div>
								<span class="fw-500">End : </span>
								<template v-if="row.project_end_date">
									{{ formatDate(row.project_end_date) }}
								</template>
								<template v-else>
									<em class="text-muted">no start date</em>
								</template>
							</div>
							<div>
								<span class="fw-500">Status : </span>
								<v-tooltip top>
									<template v-slot:activator="{ on, attrs }">
										<v-chip
											:color="getStatusColor(row.project_status_value || 'draft')"
											label
											outlined
											small
											v-on="on"
											v-bind="attrs"
											class="white--text"
										>
											<span class="text-uppercase">
												{{ row.project_status_value }}
											</span>
										</v-chip>
									</template>
									<span>Project Status</span>
								</v-tooltip>
							</div>
						</td>
						<td class="td_column cursor-default simple-table-td">
							<div>
								<v-icon small left>mdi-account</v-icon>
								<ShowValue
									tooltip
									tooltip-text="customer company name"
									:object="row"
									object-key="customer_company_name"
									label="customer company name"
								></ShowValue>
							</div>
							<div>
								<v-icon small left>mdi-home-city-outline</v-icon>
								<ShowValue
									tooltip
									tooltip-text="customer name"
									:object="row"
									object-key="customer_name"
									label="Customer Name"
								></ShowValue>
							</div>
							<div>
								<v-icon small left>mdi-email</v-icon>
								<ShowValue
									tooltip
									click-to-copy
									tooltip-text="customer email"
									:object="row"
									object-key="customer_email"
									label="customer email"
								></ShowValue>
							</div>
							<div>
								<v-icon small left>mdi-phone</v-icon>
								<ShowValue
									tooltip
									click-to-copy
									tooltip-text="Customer Phone"
									:object="row"
									object-key="customer_phone"
									label="Customer Phone"
								>
								</ShowValue>
							</div>
						</td>
						<td class="td_column cursor-default simple-table-td">
							<div>
								<Chip
									tooltip
									tooltip-text="Created By"
									small
									:text="row.addedBy"
									color="blue darken-4 white--text"
									class="mb-1"
								></Chip>
							</div>
							<TableDateTime
								small
								v-if="row.created_at"
								:human-format="row.added_at"
								:system-format="row.created_at"
							></TableDateTime>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="11">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no purchase orders at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</v-simple-table>
		</div>
		<div v-else class="mx-20 my-40 text-center">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>

		<!-- <DeleteTemplate
			v-if="deleteDialog"
			type="Quotation"
			v-on:success="deleteQuotation"
			v-on:close="(deleteDialog = false), (uuid = null)"
			:delete-dialog="deleteDialog"
			:deleteText="singleQuotation.title"
			:deleteByUrl="false"
		>
		</DeleteTemplate> -->
	</div>
</template>
<script>
import ShowValue from "@/view/components/ShowValue.vue";

// import DeleteTemplate from "@/view/components/DeleteTemplate.vue";
import Chip from "@/view/components/Chip";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { getPurchaseOrderByProject, deleteQuotation } from "@/core/lib/project.lib";
import TableDateTime from "@/view/components/TableDateTime";
import ShowPrice from "@/view/components/ShowPrice.vue";
export default {
	name: "asset-internal-listing",
	title: "Listing Internal Asset",
	components: {
		ShowValue,
		Chip,
		// DeleteTemplate,
		TableDateTime,
		ShowPrice,
	},
	data() {
		return {
			deleteDialog: false,
			pageLoading: false,
			quotation_analysis: null,
			uuid: null,
			singleQuotation: null,
			purchaseOrderListing: [],
		};
	},
	props: {
		relationTypeId: {
			type: Number,
			default: 0,
		},
		project: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		openDeleteConfirmationDialog(row) {
			this.deleteDialog = true;
			this.singleQuotation = row;
			this.uuid = row.uuid;
		},
		async deleteQuotation() {
			this.deleteDialog = false;
			try {
				this.pageLoading = true;
				await deleteQuotation(this.uuid);
				this.uuid = null;
				this.$store.commit(SET_MESSAGE, [
					{ model: true, message: `Success ! Quotation has been deleted.` },
				]);
				await this.getQuotationList();
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},

		goToQuotationCreate() {
			this.$router
				.push({
					name: "quotation-create",
					query: { project: this.$props.project.id, customer: this.$props.project.contact },
				})
				.catch(() => {});
		},
		goToPODetail(row) {
			//console.log(row.uuid);
			this.$router.push({ name: "purchase-order-detail", params: { uuid: row.uuid } });
		},
		goToQuotationDetail(quotation) {
			this.$router
				.push({
					name: "quotation-detail",
					params: { uuid: quotation.uuid },
				})
				.catch(() => {});
		},
		async getQuotationList() {
			try {
				this.pageLoading = true;
				const { tbody } = await getPurchaseOrderByProject(this.$route.params.uuid);
				
				this.purchaseOrderListing = tbody;
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
	},	
	async mounted() {
		this.internal = true;
		await this.getQuotationList();
	},
};
</script>
