<template>
	<div>
		<v-layout class="min-height-57px">
			<v-flex class="font-level-3-bold my-auto d-flex justify-space-between w-100">
				<div class="detail-svg-icon d-flex align-center">
					<!--begin::Svg Icon-->
					<inline-svg
						class="mr-2"
						:src="$assetURL('media/custom-svg/profrom2.svg')"
						height="20"
						width="20"
					/>
					<!--end::Svg Icon-->
					<template v-if="title">
						<span>{{ title }}</span>
					</template>
					<template v-else>Proforma</template>
				</div>
			</v-flex>
		</v-layout>
		<div
			v-if="!pageLoading"
			class="overflow-y"
			style="max-height: calc(100vh - 337px); min-height: 200px"
		>
			<v-simple-table class="bt-table listing-table table-head-sticky">
				<thead>
					<tr>
						<th width="10px">#</th>
						<th>Title</th>
						<th>Amount</th>
						<th width="150px">Invoice Amount</th>
						<th>Status</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody v-if="getProforma.length">
					<tr v-for="(row, index) in getProforma" :key="index + 1">
						<td width="10px">{{ index + 1 }}</td>
						<td class="text-capitalize truncate-text">
							<ShowValue :object="row" object-key="title" label="Tittle"></ShowValue>
						</td>

						<td width="60px">
							<ShowPrice :object="row" object-key="amount" label="Amount"> </ShowPrice>
						</td>
						<td width="60px">
							<ShowPrice :object="row" object-key="invoice_amount" label="Invoice Amount"> </ShowPrice>
						</td>
						<td>
							<v-col md-12>
								<Chip
									tooltip
									tooltip-text="Status"
									:text="row.status_value"
									:color="
										row.status_value === 'rejected'
											? 'red'
											: row.status_value === 'accepted'
											? 'green'
											: 'blue darken-4'
									"
								>
								</Chip>
							</v-col>
						</td>
						<td class="simple-table-td">
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										:disabled="row.status_value === 'accepted'"
										@click.prevent.stop="openApproveDialog(row)"
										v-bind="attrs"
										v-on="on"
										:color="row.status_value === 'accepted' ? '' : 'success lighten-1'"
										fab
										x-small
									>
										<v-icon color="white"> mdi-check</v-icon>
									</v-btn>
								</template>
								<span>Approve Proforma</span>
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<span v-bind="attrs" v-on="on">
										<v-btn
											:disabled="row.status_value === 'accepted'"
											@click.prevent.stop="OpenEditDialog(row)"
											class="mx-2"
											:color="row.status_value === 'accepted' ? '' : 'blue darken-4'"
											fab
											x-small
										>
											<v-icon color="white">mdi-pencil</v-icon></v-btn
										>
									</span>
								</template>
								<span>Update Proforma</span>
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<span v-bind="attrs" v-on="on">
										<v-btn
											:disabled="row.status_value === 'accepted' || row.status_value === 'rejected'"
											@click.prevent.stop="openRejectDialog(row)"
											:color="
												row.status_value === 'accepted' || row.status_value === 'rejected' ? '' : 'orange'
											"
											fab
											x-small
										>
											<v-icon color="white">mdi-close</v-icon></v-btn
										>
									</span>
								</template>
								<span>Reject Proforma</span>
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<span v-bind="attrs" v-on="on">
										<v-btn
											:disabled="row.status_value === 'accepted'"
											@click.prevent.stop="openDeleteDialog(row)"
											class="mx-2"
											:color="row.status_value === 'accepted' ? '' : 'red lighten-1'"
											fab
											x-small
										>
											<v-icon color="white"> mdi-trash-can-outline</v-icon>
										</v-btn>
									</span>
								</template>
								<span>Delete Proforma</span>
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<span v-bind="attrs" v-on="on">
										<v-btn
											:disabled="!row.status_value === 'accepted'"
											@click.prevent.stop="createInvoice(row)"
											class="mx-2"
											:color="!row.status_value === 'accepted' ? '' : 'cyan	 lighten-1'"
											fab
											x-small
										>
											<!-- <v-icon color="white"> mdi-trash-can-outline</v-icon> -->
											<inline-svg
												fill="white"
												:src="$assetURL('media/custom-svg/receipt_long.svg')"
												height="18px"
												width="18px"
											/>
										</v-btn>
									</span>
								</template>
								<span>Generate Invoice</span>
							</v-tooltip>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="11">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no Proforma at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</v-simple-table>
		</div>
		<div v-else class="mx-20 my-40 text-center">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>
		<CreateProformaDialog
			:project="project"
			:addDialog="isProformaDialogOpen"
			:milestoneId="milestoneSNO"
			:currentProforma="currentProforma"
			@close="(isProformaDialogOpen = false), (milestoneSNO = null)"
			showMoreFields
			@load="initProforma()"
		/>

		<DeleteTemplate
			type="Proforma"
			v-on:success="deleteProforma"
			v-on:close="isDeleteDialogOpen = false"
			:delete-dialog="isDeleteDialogOpen"
			:deleteText="proformaSNO"
			:deleteByUrl="false"
		>
		</DeleteTemplate>

		<Dialog :dialog="rejectDialog" :dialog-width="600">
			<template v-slot:title> Reject Proforma</template>
			<template v-slot:body>
				<v-form ref="rejectForm" v-model.trim="formValid" lazy-validation>
					<v-row class="delete-dialog">
						<v-col md="12" class="py-0 red--text"> Are you sure want to reject Proforma </v-col>
						<v-col md="12" class="py-0">
							<TextAreaInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="remark"
								placeholder="Enter Remark"
								:rules="[vrules.required(rejectRemark, 'Remark')]"
								:class="{
									required: !rejectRemark,
								}"
								v-model="rejectRemark"
							></TextAreaInput>
						</v-col>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn depressed tile :disabled="pageLoading" v-on:click="rejectDialog = false">
					No, Close
				</v-btn>
				<slot name="extra-btn"></slot>
				<v-btn
					class="white--text"
					:loading="pageLoading"
					:disabled="pageLoading"
					depressed
					color="red lighten-1"
					tile
					v-on:click="rejectProforma()"
				>
					Yes! Reject
				</v-btn>
			</template>
		</Dialog>

		<ApproveConfirmation
			v-if="approveDialog"
			type="Proforma"
			:updateStatus="false"
			v-on:success="approveProforma()"
			v-on:close="approveDialog = false"
			:approve-dialog="approveDialog"
			status="mark_as_approved"
			:uuid="proformaSNO"
			approve-type="quotation"
		>
		</ApproveConfirmation>

		<GenerateInvoice
			v-if="generatePerformsDialog"
			:dialog="generatePerformsDialog"
			:performId="performId"
			:project="project"
			@close="(generatePerformsDialog = false), (performId = null)"
		/>
	</div>
</template>
<script>
import ShowValue from "@/view/components/ShowValue.vue";
import Dialog from "@/view/components/Dialog.vue";
import { mapGetters } from "vuex";
import ShowPrice from "@/view/components/ShowPrice.vue";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import CreateProformaDialog from "@/view/components/CreateProforma";
import ListingMixin from "@/core/mixins/listing.mixin";
import {
	GET_PROFORMA_BY_PROJECT,
	DELETE_PROFORMA,
	UPDATE_PROFORMA_STATUS,
} from "@/core/services/store/proforma.module.js";
import ApproveConfirmation from "@/view/components/ApproveConfirmation";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import TextAreaInput from "@/view/components/TextAreaInput";
import GenerateInvoice from "@/view/components/GenerateInvoice";
import Chip from "@/view/components/Chip";
export default {
	name: "proforma-listing",
	title: "Proforma Listing",
	mixins: [ListingMixin],
	components: {
		ShowValue,
		ShowPrice,
		Dialog,
		TextAreaInput,
		CreateProformaDialog,
		ApproveConfirmation,
		DeleteTemplate,
		Chip,
		GenerateInvoice,
	},
	data() {
		return {
			isProformaDialogOpen: false,
			proformaSNO: null,
			milestoneSNO: null,
			approveDialog: false,
			currentProforma: null,
			generatePerformsDialog: false,
			isDeleteDialogOpen: false,
			isMilestoneDetailDialogOpen: false,
			rejectDialog: false,
			uuid: null,
			rejectRemark: null,
			formValid: null,
			performId: null,
		};
	},
	props: {
		title: {
			type: String,
			default: null,
		},
		project: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		async approveProforma() {
			this.pageLoading = true;
			const params = {
				action: "accepted",
				uuid: this.proformaSNO,
			};
			try {
				await this.$store.dispatch(UPDATE_PROFORMA_STATUS, params);
				this.approveDialog = false;
				this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Proforma has been approve." },
				]);
				await this.initProforma();
				this.proformaSNO = null;
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		async rejectProforma() {
			const formErrors = this.validateForm(this.$refs.rejectForm);
			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs.rejectForm.validate()) {
				return false;
			}

			this.pageLoading = true;
			const params = {
				action: "rejected",
				uuid: this.proformaSNO,
				remark: this.rejectRemark,
			};
			try {
				await this.$store.dispatch(UPDATE_PROFORMA_STATUS, params);

				this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Proforma has been reject." },
				]);
				await this.initProforma();
				this.rejectDialog = false;
				this.proformaSNO = null;
				this.rejectRemark = null;
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		openApproveDialog(proforma) {
			this.approveDialog = true;
			this.proformaSNO = proforma.uuid;
		},
		openDeleteDialog(proforma) {
			this.isDeleteDialogOpen = true;
			this.proformaSNO = proforma.uuid;
		},
		async deleteProforma() {
			this.pageLoading = true;
			try {
				await this.$store.dispatch(DELETE_PROFORMA, this.proformaSNO);
				this.pageLoading = false;
				this.isDeleteDialogOpen = false;
				this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Proforma has been deleted." },
				]);
				await this.initProforma();
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				this.pageLoading = false;
			}
		},
		openRejectDialog(proforma) {
			this.rejectDialog = true;
			this.proformaSNO = proforma.uuid;
		},

		async initProforma() {
			this.pageLoading = true;
			try {
				await this.$store.dispatch(GET_PROFORMA_BY_PROJECT, this.$route.params.uuid);
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		OpenEditDialog(row) {
			console.log(row.project_milestone);
			// this.currentProforma = row;
			// this.isProformaDialogOpen = true;
			// this.uuid = row.uuid;
			// this.milestoneSNO = row.project_milestone;
			// this.$router.push({
			// 	name: "proforma-create",
			// 	params: { uuid: this.project.uuid },
			// 	query: { mileUUID: row.project_milestone, t: new Date().getTime(), lineitems: false, proformaUUID: row.uuid },
			// });
		},
		createInvoice(proforma) {
			this.$router.push({
				name: "create-invoice",
				params: { uuid: this.project.uuid },
				query: { pfuuid: proforma.id, t: new Date().getTime() },
			});
			// this.performId = proforma.id;
			// this.generatePerformsDialog = true;
		},
	},
	computed: {
		...mapGetters(["getProforma", "errors"]),
	},
	async mounted() {
		await this.initProforma();
	},
};
</script>
