<template>
	<v-sheet class="project" style="height: calc(100vh - 88px)">
		<template v-if="contentLoaded">
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<div class="d-flex pb-3">
						<p class="my-auto font-level-3-bold">
							<span class="fw-500">
								<!-- <b>
									<ShowValue :object="project" object-key="name" label="project name"></ShowValue>
								</b> -->
								<v-chip v-if="project?.barcode" color="cyan" class="ml-2" label text-color="white">{{
									project?.barcode
								}}</v-chip>
								<template v-if="project.status == 1">
									<v-chip class="ml-2" color="orange" text-color="white" label> Draft </v-chip>
								</template>
								<template v-if="project.status == 2">
									<v-chip class="ml-2" color="blue" text-color="white" label> In-Progress </v-chip>
								</template>
								<template v-if="project.status == 3">
									<v-chip class="ml-2" color="green" text-color="white" label> Completed </v-chip>
								</template>
								<span class="ml-2 blue--text darken-4">{{ project.name }}</span>
							</span>
						</p>
						<v-spacer></v-spacer>
						<div v-if="project.status != 3">
							<v-btn
								depressed
								tile
								class="blue darken-4 mr-2 my-auto"
								color="white--text "
								v-on:click="doAction('edit')"
							>
								<v-icon class="mr-1">mdi-pencil</v-icon>
								Edit
							</v-btn>
						</div>

						<v-menu
							bottom
							left
							origin="center center"
							close-on-click
							close-on-content-click	
							transition="slide-y-transition"
							rounded="0"
							offset-y
							max-height="400px"
							content-class="white-background"
						>
							<template v-if="project.status != 3" v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="blue darken-4"
									class="text-white"
									tile
									:disabled="pageLoading"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon left>mdi-chevron-down</v-icon>
									Bulk Action
								</v-btn>
							</template>
							<v-list class="py-0">
								<template v-for="(item, i) in listingBulkAction">
									<v-list-item link :key="i" v-on:click="changeProjectStatus(`mark_as_${item.value}`)">
										<v-list-item-title
											><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
											>{{ item.title }}</v-list-item-title
										>
									</v-list-item>
									<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
								</template>
							</v-list>
						</v-menu>

						<div>
							<v-btn
								v-if="getPermission('project:delete')"
								depressed
								tile
								class="blue darken-4 mr-2 my-auto"
								color="white--text "
								v-on:click="doAction('delete')"
							>
								<v-icon class="mr-1">mdi-delete</v-icon>
								Delete
							</v-btn>
						</div>

						<div>
							<template v-if="getPermission('project:update')">
								<v-btn
									v-if="project.status == 1"
									depressed
									tile
									class="blue darken-4 mr-2 my-auto"
									color="white--text "
									v-on:click="startProjectDialog = true"
								>
									Start
								</v-btn>
							</template>
						</div>
						<div>
							<template v-if="getPermission('project:update')">
								<v-btn
									v-if="project.status == 2"
									depressed
									tile
									class="blue darken-4 mr-2 my-auto"
									color="white--text "
									v-on:click="completeProjectDialog = true"
								>
									Complete
								</v-btn>
							</template>
						</div>
						<div>
							<v-btn :disabled="pageLoading" depressed tile v-on:click="goBack()">
								<v-icon small left>mdi-arrow-left</v-icon> Back </v-btn>
						</div>
					</div>
					<div class="d-flex">
						<div class="d-flex pb-3 w-33">
							<div>
								<div>
									<span class="fw-600">Customer # : </span>
									<span class="fw-500">
										<v-chip v-if="customer?.barcode" color="cyan" class="ml-2" label text-color="white">{{
											customer.barcode
										}}</v-chip>
									</span>
								</div>
								<div class="my-3">
									<span class="fw-600">Company Name : </span>
									<span class="fw-500">
										<ShowValue :object="customer" object-key="company_name" label="Company Name"> </ShowValue>
									</span>
								</div>
								<div>
									<span class="fw-600">Display Name : </span>
									<span class="fw-500">
										<ShowValue :object="customer" object-key="display_name" label="Display Name"> </ShowValue>
									</span>
								</div>
								<div class="mt-3">
									<span class="fw-600">Email : </span>
									<span class="fw-500">
										<ShowValue class="ml-2" :object="customer" object-key="email" label="Email"> </ShowValue>
									</span>
								</div>
							</div>
						</div>
						<div class="pb-3 w-33">
							<div class="my-3">
								<span class="fw-600">Phone : </span>
								<span class="fw-500">
									<ShowValue class="ml-2" :object="customer" object-key="phone" label="Phone"> </ShowValue>
								</span>
							</div>
							<div>
								<span class="fw-600">Reference : </span>
								<span class="fw-500">
									<ShowValue class="ml-2" :object="project" object-key="reference_no" label="Reference">
									</ShowValue>
								</span>
							</div>
							<div class="my-3">
								<span class="fw-600">Start Date : </span>
								<span class="fw-500 no-wrap">
									{{ formatDate(project.start_date) }}
								</span>
							</div>
							<div>
								<span class="fw-600">End Date : </span>
								<span class="fw-500 no-wrap">
									{{ formatDate(project.end_date) }}
								</span>
							</div>
						</div>
						<div class="pb-3 w-33">
							<div  class="my-3">
								<span class="fw-600">Project Value : </span>
								<span class="fw-500">
									<ShowPrice class="ml-2" :object="project" object-key="project_value" label="Project Value">
									</ShowPrice>
								</span>
							</div>
							<div>
								<span class="fw-600">Project Budget : </span>
								<span class="fw-500">
									<ShowPrice class="ml-2" :object="project" object-key="cost" label="cost"> </ShowPrice>
								</span>
							</div>
							<div class="my-3">
								<span class="fw-600">Quotation Value : </span>
								<span class="fw-500">
									<ShowPrice
										class="ml-2"
										:object="project"
										object-key="quotation_value"
										label="quotation_value"
									>
									</ShowPrice>
								</span>
							</div>
						</div>
					</div>
				</v-flex>
			</v-layout>
			<div>
				<v-tabs
					v-model="projectTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#detail">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon> Overview</v-tab
					>
					<v-tab href="#task"> <v-icon small left>mdi-calendar-check</v-icon> Tasks</v-tab>

					<v-tab href="#line_items">
						<v-icon small left>mdi-account-group-outline</v-icon> Deliverable Line Items</v-tab
					>
					<v-tab href="#budget"> 
						<v-flex class="font-level-3-bold my-auto mx-2">
							<span class="detail-svg-icon mr-2">
								<inline-svg
									style="width: 30px; height: 30px"
									fill="black"
									:src="$assetURL('media/custom-svg/task_1.svg')"
								/>
							</span>
							Budget
						</v-flex>
						</v-tab>
					<v-tab href="#purchase_order">
						<inline-svg
							:fill="projectTab === 'purchase_order' ? 'white' : 'Gray'"
							:src="$assetURL('media/custom-svg/purchase-order.svg')"
							height="15"
							width="15"
							class="mr-2"
						/>Purchase Order</v-tab
					>
					<!-- <v-tab v-if="getPermission('transaction:view')" href="#transaction">
						<v-icon small left>mdi-share-outline</v-icon> Transaction
					</v-tab>
					<v-tab v-if="false" href="#asset"> <v-icon small left>mdi-share-outline</v-icon> Asset</v-tab>
					<v-tab v-if="false" href="#asset-stock">
						<v-icon small left>mdi-share-outline</v-icon> Asset Stock</v-tab
					>
					<v-tab href="#milestone"> <v-icon small left>mdi-flag</v-icon> Milestones</v-tab>
					<v-tab href="#proforma"> <v-icon small left>mdi-receipt</v-icon>Proformas</v-tab>

					<v-tab href="#invoice">
						<inline-svg
							:fill="projectTab === 'invoice' ? 'white' : 'Gray'"
							:src="$assetURL('media/custom-svg/receipt_long.svg')"
							height="15"
							width="15"
							class="mr-2"
						/>Invoices</v-tab
					>
					<v-tab href="#payment">
						<inline-svg
							:fill="projectTab === 'payment' ? 'white' : 'Gray'"
							:src="$assetURL('media/custom-svg/payment.svg')"
							height="15"
							width="15"
							class="mr-2"
						/>
						Payments</v-tab
					> -->
					<v-tab href="#quotation"
						><inline-svg
							:fill="projectTab === 'quotation' ? 'white' : 'Gray'"
							:src="$assetURL('media/custom-svg/quotation1.svg')"
							height="15"
							width="15"
							class="mr-2"
						/>Quotation</v-tab
					>

					<v-tab href="#team-member">
						<v-icon small left>mdi-account-group-outline</v-icon> Team Member</v-tab
					>
					<v-tab href="#approve-member">
					<inline-svg
							:fill="projectTab === 'quotation' ? 'white' : 'Gray'"
							:src="$assetURL('media/custom-svg/approved-person.svg')"
							height="15"
							width="15"
							class="mr-2"
						/> Approved Person</v-tab
					>
					<v-tab href="#project-transfer">
					<inline-svg
							:fill="projectTab === 'quotation' ? 'white' : 'Gray'"
							:src="$assetURL('media/custom-svg/approved-person.svg')"
							height="15"
							width="15"
							class="mr-2"
						/> Project Transfers</v-tab
					>

					<v-tab href="#file"> <v-icon small left>mdi-file-document-multiple</v-icon>Files</v-tab>
					<v-tab href="#event"> <v-icon small left>mdi-history</v-icon>Logs</v-tab>
				</v-tabs>

				<v-tabs-items v-model="projectTab">
					<v-tab-item value="detail" v-if="projectTab === 'detail'">
						<div class="d-flex mt-3">
							<div class="overflow-y w-50" style="max-height: calc(100vh - 250px)">
								<v-layout class="mb-2 min-height-45px mb-3" style="background-color: #f1f6f9">
									<v-flex class="font-level-3-bold my-auto mx-2">
										<inline-svg
											style="width: 25px; height: 25px"
											fill="#006fc8"
											:src="$assetURL('media/custom-svg/overview3.svg')"
										/>
										Project Detail
									</v-flex>
								</v-layout>
								<div class="mx-4 mb-12">
									<div class="d-flex mb-4">
										<div class="d-flex">
											<div class="fw-600 w-150">Description</div>
											<div class="fw-500 ml-4">
												<ShowTextAreaValue
													:object="project"
													object-key="description"
													label="Project Description"
												>
												</ShowTextAreaValue>
											</div>
										</div>
									</div>
									<div class="d-flex mb-4">
										<div class="d-flex">
											<div class="fw-600 w-150">Specification</div>
											<div class="fw-500 ml-4">
												<ShowValue :object="project" object-key="specifications" label="Specification">
												</ShowValue>
											</div>
										</div>
									</div>
								</div>
								<v-layout class="mb-2 min-height-45px mb-3 mt-12" style="background-color: #fff8d6">
									<v-flex class="font-level-3-bold my-auto mx-2">
										<span class="detail-svg-icon mr-2">
											<inline-svg
												style="width: 25px; height: 25px"
												fill="#006fc8"
												:src="$assetURL('media/custom-svg/bank.svg')"
											/>
										</span>
										Banker Guarantee
									</v-flex>
								</v-layout>
								<div class="mx-4 mb-12">
									<div class="d-flex w-100 mb-4">
										<div class="w-50 d-flex">
											<div class="fw-600 w-150">Bank Name</div>
											<div class="fw-500 ml-4">
												<ShowValue :object="project" object-key="bankers_guarantee_name" label="Bank Name">
												</ShowValue>
											</div>
										</div>
										<div class="w-50 d-flex">
											<div class="fw-600 w-150">Amount</div>
											<div class="fw-500 ml-4">
												<ShowPrice :object="project" object-key="bankers_guarantee_amount" label="Bank Amount">
												</ShowPrice>
											</div>
										</div>
									</div>
									<div class="d-flex w-100">
										<div class="fw-600 w-150">Remark</div>
										<div class="fw-500 ml-4">
											<ShowTextAreaValue
												:object="project"
												object-key="bankers_guarantee_remark"
												label="Remark"
											>
											</ShowTextAreaValue>
										</div>
									</div>
								</div>
								<v-layout class="mb-2 min-height-45px mb-3 mt-12" style="background-color: #c4dfdf">
									<v-flex class="font-level-3-bold my-auto mx-2">
										<span class="detail-svg-icon mr-2">
											<inline-svg
												style="width: 35px; height: 35px"
												fill="#006fc8"
												:src="$assetURL('media/custom-svg/insurance.svg')"
											/>
										</span>
										Insurance Bank
									</v-flex>
								</v-layout>
								<div class="mx-4 mb-12">
									<div class="d-flex w-100 mb-4">
										<div class="w-50 d-flex">
											<div class="fw-600 w-150">Insurance Bank</div>
											<div class="fw-500 ml-4">
												<ShowValue
													:object="project"
													object-key="insurance_bank_name"
													label="Insurance Bank Name"
												>
												</ShowValue>
											</div>
										</div>
										<div class="w-50 d-flex">
											<div class="fw-600 w-150">Amount</div>
											<div class="fw-500 ml-4">
												<ShowPrice
													:object="project"
													object-key="insurance_bank_amount"
													label="Insurance Amount"
												>
												</ShowPrice>
											</div>
										</div>
									</div>
									<div class="d-flex w-100">
										<div class="fw-600 w-150">Remark</div>
										<div class="fw-500 ml-4">
											<ShowTextAreaValue
												:object="project"
												object-key="insurance_bank_remark"
												label="Insurance Remark"
											>
											</ShowTextAreaValue>
										</div>
									</div>
								</div>
							</div>
							<div class="w-50 ml-8">
								<v-layout class="mb-2 min-height-45px mb-3" style="background-color: #f6ffde">
									<v-flex class="font-level-3-bold my-auto mx-2">
										<span class="detail-svg-icon mr-2">
											<inline-svg
												style="width: 30px; height: 30px"
												fill="#006fc8"
												:src="$assetURL('media/custom-svg/task_1.svg')"
											/>
										</span>
										Tasks
									</v-flex>
								</v-layout>
								<div id="chart">
									<apexchart
										v-if="
											project.task_data?.tasks_completed ||
											project.task_data?.tasks_pending ||
											project.task_data?.tasks_inprogress
										"
										type="pie"
										width="380"
										:options="chartOptions"
										:series="series"
									></apexchart>
									<div v-else>
										<h2 class="text-center mt-12 fw-600 grey--text" style="font-style: italic">
											No Task Created
										</h2>
									</div>
								</div>
								<!-- <div class="font-level-4-bold mt-6">Budget:</div>
								<div id="chart">
									<apexchart type="bar" height="430" :options="barChartOptions" :series="groupSeries">
									</apexchart>
								</div> -->
							</div>
						</div>
					</v-tab-item>
					<v-tab-item value="transaction">
						<template v-if="projectTab == 'transaction'">
							<TransactionListing
								relation-type="project"
								:relation-type-id="project.id"
							></TransactionListing>
							<TransactionListing
								relation-type="project"
								:relation-type-id="project.id"
							></TransactionListing>
						</template>
					</v-tab-item>
					<v-tab-item value="purchase-order">
						<CheckoutListing
							v-if="project && project.id && projectTab == 'asset'"
							class="mx-4"
							relation-type="project"
							:relation-type-id="project.id"
							type="asset"
							title="Assets"
						>
						</CheckoutListing>
					</v-tab-item>
					<v-tab-item value="budget">
						<ProjectBudgetListing
							v-if="project && project.id && projectTab == 'budget'"
							class="mx-4"
							relation-type="project"
							:relation-type-id="project.id"
							type="asset"
							title="Assets"
							:project-status=" project && project.status"
						>
						</ProjectBudgetListing>
					</v-tab-item>
					<v-tab-item value="asset">
						<CheckoutListing
							v-if="project && project.id && projectTab == 'asset'"
							class="mx-4"
							relation-type="project"
							:relation-type-id="project.id"
							type="asset"
							title="Assets"
						>
						</CheckoutListing>
					</v-tab-item>
					<v-tab-item value="purchase_order">
						<PurchaseOrderListing :project="project" 	class="mx-4"> </PurchaseOrderListing>
					</v-tab-item>
					<v-tab-item value="members">
						<v-simple-table class="bt-table mt-3">
							<thead>
								<tr>
									<th>
										<v-layout>
											<v-flex class="text-left"> Image </v-flex>
										</v-layout>
									</th>
									<th>
										<v-layout>
											<v-flex class="text-left"> Incharge Officer # </v-flex>
										</v-layout>
									</th>
									<th>
										<v-layout>
											<v-flex class="text-left"> Email </v-flex>
										</v-layout>
									</th>
									<th>
										<v-layout>
											<v-flex class="text-left"> Display Name </v-flex>
										</v-layout>
									</th>
									<th>
										<v-layout>
											<v-flex class="text-left"> Identification Number </v-flex>
										</v-layout>
									</th>
									<th>
										<v-layout>
											<v-flex class="text-left"> Phone Number </v-flex>
										</v-layout>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(td, index) in project.members" :key="index">
									<td>
										<ImageTemplate circle style="max-width: 50px" :src="td.member.image"> </ImageTemplate>
									</td>
									<td>
										<Chip :text="td.member.barcode" color="cyan"></Chip>
									</td>
									<td>
										<ShowValue :object="td" object-key="member.email" label="Email"></ShowValue>
									</td>
									<td>
										<ShowValue :object="td" object-key="member.display_name" label="Display Name">
										</ShowValue>
									</td>
									<td>
										<ShowValue :object="td" object-key="member.id_number" label="Identification Number">
										</ShowValue>
									</td>
									<td>
										<ShowValue :object="td" object-key="member.phone_number" label="Phone Number">
										</ShowValue>
									</td>
								</tr>
							</tbody>
						</v-simple-table>
					</v-tab-item>
					<v-tab-item value="task">
						<TasksListing
							v-if="project"
							:project="project"
							class="mx-4"
							@refreshGetProjectAPI="getProject()"
						>
						</TasksListing>
					</v-tab-item>
					<!-- <v-tab-item value="budget">
						<BudgetListing v-if="project" :project="project" class="mx-4"> </BudgetListing> -->
					<!-- </v-tab-item> -->
					<v-tab-item value="milestone">
						<MilestoneListing v-if="project" :project="project" class="mx-4"> </MilestoneListing>
					</v-tab-item>
					<v-tab-item value="quotation">
						<QuotationListing :project="project" class="mx-4"> </QuotationListing>
					</v-tab-item>
					<v-tab-item value="proforma">
						<ProformaListing v-if="project" :project="project" class="mx-4"> </ProformaListing>
					</v-tab-item>
					<v-tab-item value="invoice">
						<InvoiceListing class="mx-4" v-if="project && projectTab === 'invoice'" :project="project">
						</InvoiceListing>
					</v-tab-item>
					<v-tab-item value="payment">
						<PaymentListing class="mx-4" :project="project" v-if="project && projectTab === 'payment'">
						</PaymentListing>
					</v-tab-item>
					<v-tab-item value="line_items">
						<ProjectLineItems internalclass="mx-4"> </ProjectLineItems>
					</v-tab-item>
					<v-tab-item value="team-member">
						<TeamMemberListing
							class="mx-4"
							:project="project"
							v-if="project && projectTab === 'team-member'"
						>
						</TeamMemberListing>
					</v-tab-item>
						<v-tab-item value="approve-member">
						<ApproveMemberListing
							class="mx-4"
							:project="project"
							v-if="project && projectTab === 'approve-member'"
						>
						</ApproveMemberListing>
					</v-tab-item>
					
					<v-tab-item value="project-transfer">
						<ProjectTransfersListProject
							class="mx-4"
							:project="project"
							v-if="project && projectTab === 'project-transfer'"
						>
						</ProjectTransfersListProject>
					</v-tab-item>
					<v-tab-item value="asset-stock">
						<CheckoutListing
							v-if="project && project.id && projectTab == 'asset-stock'"
							class="mx-4"
							relation-type="project"
							:relation-type-id="project.id"
							type="asset-stock"
							title="Asset Stocks"
						>
						</CheckoutListing>
					</v-tab-item>
					<v-tab-item value="file">
						<Files
							v-if="projectTab == 'file'"
							class="mx-4"
							type-text="Project Files"
							type="project"
							:type-uuid="$route.params.uuid"
						>
						</Files>
					</v-tab-item>
					<v-tab-item value="event">
						<EventListing
							v-if="projectTab == 'event'"
							class="mx-4"
							type-text="Customer"
							type="project"
							:type-uuid="uuid"
						></EventListing>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<DeleteTemplate
				type="Customer"
				:delete-text="deleteText"
				v-on:success="goBack()"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
				:delete-note="
					project.activated
						? 'To preserve associated records, you could de-activate the Customer instead.'
						: null
				"
			>
				<template v-if="project.activated" v-slot:extra-btn>
					<v-btn
						class="white--text"
						depressed
						color="blue darken-4"
						tile
						v-on:click="
							deleteDialog = false;
							deactivateDialog = true;
						"
					>
						De-activate
					</v-btn>
				</template>
			</DeleteTemplate>
			<DeactivateTemplate
				type="Customer"
				:deactivate-text="deactivateText"
				v-on:success="goBack()"
				v-on:close="deactivateDialog = false"
				:deactivate-dialog="deactivateDialog"
				:deactivate-url="deactivateURL"
			>
			</DeactivateTemplate>
			<ActivateTemplate
				type="Customer"
				:activate-text="activateText"
				v-on:success="goBack()"
				v-on:close="activateDialog = false"
				:activate-dialog="activateDialog"
				:activate-url="activateURL"
			>
			</ActivateTemplate>
			<Dialog :dialog="startProjectDialog" :dialog-width="640">
				<template v-slot:title> Start Project</template>
				<template v-slot:body>
					<v-row class="delete-dialog">
						<v-col md="2" class="py-0 text-right my-auto">
							<span class="svg-icon svg-icon-lg delete-confirm-icon">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
								<!--end::Svg Icon-->
							</span>
						</v-col>
						<v-col md="10" class="py-0 my-auto">
							<p class="btx-p m-0">
								Are you sure you want to Start
								<span class="red--text font-bolder lighten-1">{{ property && property.name }}</span> Project
								?
							</p>
						</v-col>
					</v-row>
				</template>
				<template v-slot:action>
					<v-btn
						class="white--text"
						:loading="pageLoading"
						:disabled="pageLoading"
						depressed
						color="green lighten-1"
						tile
						v-on:click="startProject"
					>
						Yes! Start
					</v-btn>
					<v-btn depressed tile :disabled="pageLoading" v-on:click="startProjectDialog = false">
						No, Close
					</v-btn>
				</template>
			</Dialog>
			<Dialog :dialog="completeProjectDialog" :dialog-width="640">
				<template v-slot:title> Complete Project</template>
				<template v-slot:body>
					<v-row class="delete-dialog">
						<v-col md="2" class="py-0 text-right my-auto">
							<span class="svg-icon svg-icon-lg delete-confirm-icon">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
								<!--end::Svg Icon-->
							</span>
						</v-col>
						<v-col md="10" class="py-0 my-auto">
							<p class="btx-p m-0">
								Are you sure you want to Complete
								<span class="red--text font-bolder lighten-1">{{ property && property.name }}</span> Project
								?
							</p>
						</v-col>
					</v-row>
				</template>
				<template v-slot:action>
					<v-btn
						class="white--text"
						:loading="pageLoading"
						:disabled="pageLoading"
						depressed
						color="green lighten-1"
						tile
						v-on:click="completeProject"
					>
						Yes! Start
					</v-btn>
					<v-btn depressed tile :disabled="pageLoading" v-on:click="completeProjectDialog = false">
						No, Close
					</v-btn>
				</template>
			</Dialog>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</v-sheet>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GetProject, StartProject, CompleteProject,ChangeProjectStatus } from "@/core/lib/project.lib";
import ShowValue from "@/view/components/ShowValue";
import ShowPrice from "@/view/components/ShowPrice";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import { isEmpty } from "lodash";
import ActivateTemplate from "@/view/components/ActivateTemplate";
import DeactivateTemplate from "@/view/components/DeactivateTemplate";
import EventListing from "@/view/components/EventListing";
import CheckoutListing from "@/view/components/CheckoutListing";
import TransactionListing from "@/view/components/TransactionListing";
import Dialog from "@/view/components/Dialog.vue";
import Chip from "@/view/components/Chip";
import ImageTemplate from "@/view/components/Image";
import Files from "@/view/components/Files";
import TasksListing from "@/view/components/TasksListing";
import MilestoneListing from "@/view/components/MilestoneListing.vue";
import QuotationListing from "@/view/components/QuotationListing.vue";
// import BudgetListing from "@/view/components/BudgetListing";
import ProformaListing from "@/view/components/ProformaListing.vue";
import InvoiceListing from "@/view/components/InvoiceListing";
import ProjectLineItems from "@/view/components/ProjectLineItems";
import PaymentListing from "@/view/components/PaymentListing";
import TeamMemberListing from "@/view/components/TeamMemberListing";
import ApproveMemberListing from "@/view/components/ApproveMemberListing";
import ProjectTransfersListProject from "@/view/components/ProjectTransfersListProject";

import PurchaseOrderListing from "@/view/components/ProjectPurchaseOrderListing.vue";

import ShowTextAreaValue from "@/view/components/ShowTextAreaValue";
import ProjectBudgetListing from "@/view/components/ProjectBudgetListing";
import { SET_ERROR } from "@/core/services/store/common.module";

export default {
	name: "project-detail",
	title: "Detail Detail",
	data() {
		return {
			uuid: null,
			barcode: null,
			customer: null,
			purchase_order: null,
			property: {},
			activateText: null,
			activateURL: null,
			deactivateText: null,
			deactivateURL: null,
			deleteText: null,
			contact_person: [],
			listingBulkAction: [
				{
					id: 75,
					type: "project",
					title: "Mark as Draft",
					value: "draft",
					color: "blue",
					icon: "mdi-check-all",
					bulk: true,
					order: 1,
				},
				{
					id: 76,
					type: "project",
					title: "Mark as In-Progress",
					value: "inprogress",
					color: "blue",
					icon: "mdi-check-all",
					bulk: true,
					order: 2,
				},
				{
					id: 77,
					type: "project",
					title: "Mark as Completed",
					value: "completed",
					color: "blue",
					icon: "mdi-check-all",
					bulk: true,
					order: 3,
				},
			],
			deleteURL: null,
			startProjectDialog: false,
			completeProjectDialog: false,
			formValid: true,
			personModel: {},
			deleteDialog: false,
			addressDialog: false,
			deactivateDialog: false,
			activateDialog: false,
			contentLoaded: true,
			pageLoading: true,
			// projectTab: "detail",
			project: {
				customer: null,
				purchase_order: null,
				name: null,
				quote: null,
				reference_no: null,
				description: null,
				start_date_formatted: null,
				end_date_formatted: null,
				remark: null,
				banker_guarantee_amount: null,
				banker_guarantee_remark: null,
			},
			groupSeries: [
				{
					name: "Budget Alloted",
					data: ["44 k", "55 K", "41 k", 64, 22],
				},
				{
					name: "Spend Budget",
					data: [32, 32, 33, 52, 13],
				},
			],
			series: [25, 10, 10],
			chartOptions: {
				plotOptions: {
					pie: {
						dataLabels: {
							offset: -10,
						},
						formatter: function (val) {
							return val + "%";
						},
					},
				},
				chart: {
					width: 500,
					height: 500,
					type: "pie",
				},
				colors: ["#4CAF50", "#F44336", "#2196F3"],
				labels: ["Completed", "Pending", "In Progress"],
			},
			barChartOptions: {
				chart: {
					type: "bar",
					height: 350,
				},
				plotOptions: {
					bar: {
						horizontal: false,
						dataLabels: {
							position: "top",
						},
					},
				},
				dataLabels: {
					enabled: true,
					offsetY: 10,
					formatter: function (val) {
						return val + "K";
					},
					style: {
						fontSize: "12px",
						colors: ["#fff"],
					},
				},
				stroke: {
					show: true,
					width: 1,
					colors: ["#fff"],
				},
				yaxis: {
					title: {
						text: "Budget in thousand",
					},
				},
				tooltip: {
					y: {
						formatter: function (val) {
							return +val + "k";
						},
					},
				},
				xaxis: {
					categories: ["Budget 1", "Budget 2", "Budget 3", "Budget 4", "Budget 5"],
				},
			},
		};
	},
	methods: {
		async changeProjectStatus(type) {
			try {
				this.pageLoading = true;
				const param = {
					action: type,
					selected: [this.$route.params.uuid],
				};
				await ChangeProjectStatus(param);
				await this.getProject();

			} catch (error) {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		doAction(param) {
			switch (param) {
				case "edit":
					this.$router.push({
						name: "project-update",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime(), tab: "overview" },
					});
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "deactivate":
					this.deactivateDialog = true;
					break;
				case "activate":
					this.activateDialog = true;
					break;
				case "new-address":
					this.addressDialog = true;
					break;
				case "print-label":
					break;
			}
		},
		startProject() {
			StartProject(this.uuid)
				.then(() => {
					this.getProject();
					this.startProjectDialog = false;
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
		completeProject() {
			CompleteProject(this.uuid)
				.then(() => {
					this.getProject();
					this.completeProjectDialog = false;
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
		getProject() {
			GetProject(this.uuid)
				.then((data) => {
					if (
						data[0].task_data?.tasks_inprogress ||
						data[0].task_data?.tasks_pending ||
						data[0].task_data?.tasks_completed
					) {
						this.series = [
							data[0].task_data.tasks_completed ? data[0].task_data.tasks_completed : 0,
							data[0].task_data.tasks_pending,
							data[0].task_data.tasks_inprogress,
						];
						this.chartOptions = {
							plotOptions: {
								pie: {
									dataLabels: {
										offset: -10,
									},
									formatter: function (val) {
										return val + "%";
									},
								},
							},
							legend: {
								floating: false,
								formatter: function (val) {
									if (val == "Pending") {
										return "Pending" + ": " + data[0].task_data.tasks_pending;
									} else if (val == "Completed") {
										return val + ": " + data[0].task_data.tasks_completed;
									} else if (val == "In Progress") {
										return val + ": " + data[0].task_data.tasks_inprogress;
									}
								},
							},
							chart: {
								width: 380,
								height: 400,
								type: "pie",
							},
							colors: ["#4CAF50", "#F44336", "#2196F3"],
							labels: ["Completed", "Pending", "In Progress"],
						};
					}
					this.barcode = data[0].barcode;
					this.project = data[0];
					this.customer = this.project.customers[0];
					// this.purchase_order = this.project.purchase_order[0];
					this.property = data.property;
					this.deleteText = `#${data.barcode}`;
					this.deleteURL = `project/${data.uuid}`;
					// this.activateText = `${data.barcode}`;
					this.activateURL = `project/${data.uuid}/activate`;
					// this.deactivateText = `${data.barcode}`;
					this.deactivateURL = `project/${data.uuid}/de-activate`;
					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Project", disabled: true },
						{ text: "Detail", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					// this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
		addPerson(person) {
			this.personDialog = true;
			this.personModel = new Object();
			if (person && !isEmpty(person)) {
				this.personModel = new Object({
					id: person.id || null,
					uuid: person.uuid || null,
					salutation: person.salutation || null,
					first_name: person.first_name || null,
					last_name: person.last_name || null,
					email: person.email || null,
					mobile: person.phone || null,
					display_name: person.display_name || null,
					designation: person.designation || null,
					primary: person.primary || null,
				});
			}
		},
	},
	computed: {
		projectTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.projectTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.tab || "detail";
			},
		},
	},
	components: {
		Files,
		ShowValue,
		ShowPrice,
		ShowTextAreaValue,
		ProjectTransfersListProject,
		// BudgetListing,
		EventListing,
		DeleteTemplate,
		ActivateTemplate,
		ImageTemplate,
		Dialog,
		Chip,
		CheckoutListing,
		DeactivateTemplate,
		TransactionListing,
		TasksListing,
		MilestoneListing,
		ProformaListing,
		InvoiceListing,
		PaymentListing,
		TeamMemberListing,
		QuotationListing,
		ProjectLineItems,
		PurchaseOrderListing,
		ProjectBudgetListing,
		ApproveMemberListing
	},
	async mounted() {
		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}
		await this.getProject();
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
