<template>
	<div>
		<v-layout class="min-height-57px">
			<v-flex
				class="font-level-3-bold my-auto d-flex justify-space-between w-100 d-flex justify-space-between"
			>
				<span class="detail-svg-icon">
					<!--begin::Svg Icon-->
					<inline-svg
						fill="#006fc8"
						:src="$assetURL('media/custom-svg/receipt_long.svg')"
						height="20"
						width="20"
						class="mr-2"
					/>
					<!--end::Svg Icon-->

					<template> Invoice</template>
				</span>
				<div>
					<v-btn
						@click="openInvoiceDialog"
						color="blue darken-4 white--text"
						class="ml-4"
						tile
						depressed
					>
						<v-icon>mdi-plus</v-icon>Create</v-btn
					>
				</div>
			</v-flex>
		</v-layout>
		<div
			v-if="!pageLoading"
			class="overflow-y"
			style="max-height: calc(100vh - 337px); min-height: 200px"
		>
			<v-simple-table class="bt-table listing-table table-head-sticky">
				<thead>
					<tr>
						<th width="10px">#</th>
						<th>Title</th>
						<th>Invoice No</th>
						<th>Proforma NO</th>
						<th>Date</th>
						<th>Amount</th>

						<th>Status</th>
						<!-- <th>Actions</th> -->
					</tr>
				</thead>
				<tbody v-if="invoiceList.length">
					<tr
						class="cursor-pointer my-5"
						v-for="(row, index) in invoiceList"
						:key="index + 1"
						@click.prevent.stop="openInvoiceDetailDialog(index)"
					>
						<td width="10px">{{ index + 1 }}</td>
						<td class="text-capitalize truncate-text">
							<ShowValue :object="row" object-key="title" label="Tittle"></ShowValue>
						</td>
						<td>
							<Chip tooltip tooltip-text="Invoice No" :text="row.barcode" color="cyan"></Chip>
						</td>
						<td @click.prevent.stop="OpenProformaDialog">
							<Chip tooltip tooltip-text="Proforma No" :text="row.barcode" color="blue darken-4"></Chip>
						</td>
						<td>
							{{ formatDate(row.date) }}
						</td>
						<td>
							<ShowPrice :object="row" object-key="amount" label="Amount "></ShowPrice>
						</td>
						<td>
							<Chip
								tooltip
								tooltip-text="Status #"
								:text="row.status_value"
								:color="row.status_value === 'paid' ? 'green' : 'red'"
							></Chip>
						</td>
						<!-- <td class="simple-table-td">
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn @click.stop.prevent="openDeleteDialog(row)" color="red lighten-1" v-bind="attrs"
										class="mx-2" v-on="on" dark fab x-small>
										<v-icon small dark> mdi-trash-can-outline</v-icon>
									</v-btn>
								</template>
								<span>Delete Invoice</span>
							</v-tooltip>
						</td> -->
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="11">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no invoice at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</v-simple-table>
		</div>
		<div v-else class="mx-20 my-40 text-center">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>
		<GenerateInvoice
			v-if="addDialog"
			:dialog="addDialog"
			:project="project"
			@close="addDialog = false"
			@initInvoice="initInvoice()"
		/>
		<Dialog
			v-if="isInvoiceDetailDialogOpen"
			:dialog="isInvoiceDetailDialogOpen"
			title="Asset Stocks"
			current
			v-on:close="isInvoiceDetailDialogOpen = false"
		>
			<template v-slot:title v-if="singleInvoice.title"> {{ singleInvoice.title }} </template>
			<template v-slot:body>
				<table
					v-if="singleInvoice"
					width="100%"
					class="detail-table scattered-table"
					style="font-size: 13px"
				>
					<tr>
						<th width="150px">Title</th>
						<td>
							<ShowValue :object="singleInvoice" object-key="title" label="Title"> </ShowValue>
						</td>
						<th width="150px">Invoice No</th>
						<td>
							<Chip tooltip tooltip-text="Invoice No" :text="singleInvoice.barcode" color="cyan"></Chip>
						</td>
					</tr>
					<tr>
						<th width="150px">Date</th>
						<td>
							<!-- <ShowValue :object="singleInvoice" object-key="date" label="Date"> </ShowValue> -->
							{{ formatDate(singleInvoice.date) }}
						</td>
						<th width="150px">Amount</th>
						<td>
							<ShowPrice :object="singleInvoice" object-key="amount" label="Amount "></ShowPrice>
							<!-- <span v-if="singleInvoice.amount"> K</span> -->
						</td>
					</tr>
					<tr>
						<th width="150px">status</th>
						<td>
							<Chip
								tooltip
								tooltip-text="Status #"
								:text="singleInvoice.status_value"
								:color="singleInvoice.status_value === 'paid' ? 'green' : 'red'"
							></Chip>
						</td>
					</tr>
				</table>
			</template>
			<template v-slot:action>
				<v-btn
					depressed
					tile
					:disabled="pageLoading"
					v-on:click="
						isInvoiceDetailDialogOpen = false;
						singleInvoice = null;
					"
				>
					Close
				</v-btn>
			</template>
		</Dialog>
		<ProformaDetailDialog
			:dialogOpen="proformaDetailDialogOpen"
			@close="proformaDetailDialogOpen = false"
		/>
		<!-- <DeleteTemplate type="Invoice" v-on:success="deleteInvoice" deleteByUrl v-on:close="deleteDialog = false"
			:delete-dialog="deleteDialog" :deleteText="uuid">
		</DeleteTemplate> -->
	</div>
</template>
<script>
import ShowValue from "@/view/components/ShowValue.vue";
import ShowPrice from "@/view/components/ShowPrice.vue";
import Dialog from "@/view/components/Dialog";
import Chip from "@/view/components/Chip";
// import DeleteTemplate from "@/view/components/DeleteTemplate";
import { SET_ERROR /* SET_MESSAGE */ } from "@/core/services/store/common.module";

import { getInvoiceByProject /* deleteInvoice */ } from "@/core/lib/project.lib";
import GenerateInvoice from "@/view/components/GenerateInvoice.vue";
import ProformaDetailDialog from "@/view/components/ProformaDetailDialog.vue";
export default {
	name: "Invoice_Listing",
	title: "Invoice Listing",
	components: {
		Dialog,
		ShowValue,
		ShowPrice,
		// DeleteTemplate,
		ProformaDetailDialog,
		Chip,
		GenerateInvoice,
	},
	data() {
		return {
			uuid: null,
			pageLoading: false,
			invoiceSN: null,
			isApproveDialogOpen: false,
			proformaDetailDialogOpen: false,
			deleteDialog: false,
			isInvoiceDetailDialogOpen: false,
			addDialog: false,
			invoice_detail: {
				name: null,
				invoice_no: null,
				attachment: null,
				date: null,
				amount: null,
			},
			singleInvoice: null,
			invoiceList: [],
		};
	},
	props: {
		project: {
			type: Object,
			default: () => {},
		},
		relationTypeId: {
			type: Number,
			default: 0,
		},
	},
	methods: {
		openInvoiceDialog() {
			// this.addDialog = true;
			this.$router.push({
				name: "create-invoice",
				params: { uuid: this.project.uuid },
				query: { t: new Date().getTime() },
			});
		},
		// openDeleteDialog(proforma) {
		// 	this.uuid = proforma.uuid;
		// 	this.deleteDialog = true;
		// },
		openInvoiceDetailDialog(index) {
			this.isInvoiceDetailDialogOpen = true;
			this.singleInvoice = this.invoiceList[index];
		},
		OpenProformaDialog() {
			console.log(this.proformaDetailDialogOpen);
			this.proformaDetailDialogOpen = true;
			console.log(this.proformaDetailDialogOpen);
		},
		async initInvoice() {
			this.pageLoading = true;
			try {
				const { invoice } = await getInvoiceByProject(this.$route.params.uuid);
				this.invoiceList = invoice;
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		// async deleteInvoice() {
		// 	this.pageLoading = true;
		// 	try {
		// 		await deleteInvoice(this.uuid);
		// 		this.deleteDialog = false;
		// 		this.$store.commit(SET_MESSAGE, [
		// 			{ model: true, message: "Success ! Proforma has been deleted." },
		// 		]);
		// 		this.uuid = null;
		// 		await this.initInvoice();
		// 	} catch (error) {
		// 		this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
		// 	} finally {
		// 		this.pageLoading = false;
		// 	}
		// },
	},
	async mounted() {
		await this.initInvoice();
	},
};
</script>
