<template>
	<div>
		<v-layout class="min-height-57px">
			<v-flex
				class="font-level-3-bold my-auto d-flex justify-space-between w-100 d-flex justify-space-between"
			>
				<span class="detail-svg-icon">
					<!--begin::Svg Icon-->
					<inline-svg
						fill="#006fc8"
						:src="$assetURL('media/custom-svg/payment1.svg')"
						height="20"
						width="20"
						class="mr-2"
					/>
					<!--end::Svg Icon-->
					<template>Payment</template>
				</span>
				<div>
					<v-btn
						@click="openInvoiceDialog"
						color="blue darken-4 white--text"
						class="ml-4"
						tile
						depressed
					>
						<v-icon>mdi-plus</v-icon> Create</v-btn
					>
				</div>
			</v-flex>
		</v-layout>
		<div
			v-if="!pageLoading"
			class="overflow-y"
			style="max-height: calc(100vh - 337px); min-height: 200px"
		>
			<v-simple-table class="bt-table listing-table table-head-sticky">
				<thead>
					<tr>
						<th width="10px">#</th>
						<th>Invoice Title</th>
						<th>Invoice #</th>
						<th>Invoice Date</th>
						<th>Invoice Amount</th>
						<th>Payment Date</th>
						<th>Amount Received</th>
						<th>Reference</th>
						<!-- <th>Status</th> -->
						<th>Actions</th>
					</tr>
				</thead>
				<tbody v-if="paymentList.length">
					<tr
						class="cursor-pointer"
						v-for="(row, index) in paymentList"
						:key="index + 1"
						@click="openDetailDialog(row)"
					>
						<td width="10px">{{ index + 1 }}</td>
						<td>
							<ShowValue :object="row" object-key="invoice_title" label="Invoice Tittle"> </ShowValue>
						</td>
						<td>
							<Chip tooltip tooltip-text="Invoice No" :text="row.invoice_barcode" color="cyan"></Chip>
						</td>
						<td>
							<span v-if="row.p_date">{{ formatDate(row.invoice_date) }}</span>
							<em v-else class="text-muted">No Invoice Date</em>
						</td>
						<td>
							<ShowPrice :object="row" object-key="invoice_amount" label="invoice_amount"></ShowPrice>
						</td>
						<td>
							<span v-if="row.p_date">{{ formatDate(row.p_date) }}</span>
							<em v-else class="text-muted">No Payment Date</em>
						</td>
						<td>
							<ShowPrice :object="row" object-key="p_amount" label="Amount"></ShowPrice>
						</td>

						<td>
							<ShowValue :object="row" object-key="reference" label="Reference"></ShowValue>
						</td>

						<!-- <td>
							<Chip
								tooltip
								tooltip-text="Status #"
								:text="row.status_value"
								:color="
									row.status_value === 'paid'
										? 'green'
										: row.status_value === 'partial-paid'
										? 'blue'
										: 'red'
								"
							>
							</Chip>
						</td> -->
						<td>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										@click.prevent.stop="openDeleteDialog(row)"
										color="red lighten-1"
										v-bind="attrs"
										class="mx-2"
										v-on="on"
										dark
										fab
										x-small
									>
										<v-icon small dark> mdi-trash-can-outline</v-icon>
									</v-btn>
								</template>
								<span>Delete Payment</span>
							</v-tooltip>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="11">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no payment at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</v-simple-table>
		</div>
		<div v-else class="mx-20 my-40 text-center">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>
		<Dialog
			v-if="addDialog"
			:dialog="addDialog"
			title="Generate Payment"
			current
			v-on:close="addDialog = false"
		>
			<template v-slot:title> Generate Payment </template>
			<template v-slot:body>
				<table width="100%" class="detail-table scattered-table">
					<div>
						<v-form v-if="addDialog" ref="paymentForm">
							<v-row>
								<v-col md="3">
									<label for="date" class="btx-label mt-4">Date</label>
								</v-col>

								<v-col md="9" class="py-0">
									<DatePicker
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										v-model="payment.date"
									></DatePicker>
								</v-col>
								<v-col md="3">
									<label for="reference" class="btx-label mt-4">Reference</label>
								</v-col>
								<v-col md="9">
									<TextInput
										id="reference"
										hide-details
										v-model="payment.reference"
										placeholder="Reference"
										:disabled="pageLoading"
										:loading="pageLoading"
									></TextInput>
								</v-col>
								<v-col md="3">
									<label for="amount" class="btx-label mt-4">Amount Received</label>
								</v-col>
								<v-col md="9">
									<TextInput
										id="amount"
										hide-details
										type="number"
										v-model="payment.amount_received"
										placeholder="Amount"
										:disabled="pageLoading"
										:loading="pageLoading"
									></TextInput>
								</v-col>
								<v-col md="3">
									<label for="invoice" class="btx-label mt-4">Invoice</label>
								</v-col>
								<v-col md="9">
									<AutoCompleteInput
										hide-details
										:items="invoiceList"
										:disabled="pageLoading"
										item-text="barcode"
										item-value="id"
										:loading="pageLoading"
										id="invoice"
										placeholder="Invoice"
										v-model="payment.invoice"
										:class="{ required: !payment.text }"
									>
									</AutoCompleteInput>
								</v-col>
								<v-col md="3">
									<label for="documents" class="btx-label mt-4">Documents</label>
								</v-col>
								<v-col md="9">
									<FileUpload :allowAddMore="false" id="documents" v-model="payment.documents"> </FileUpload>
								</v-col>
							</v-row>
						</v-form>
					</div>
				</table>
			</template>
			<template v-slot:action>
				<v-btn depressed tile :disabled="pageLoading" v-on:click="addDialog = false"> Close </v-btn>
				<v-btn class="white--text" depressed color="blue darken-4" tile @click="updateOrCreate">
					Save
				</v-btn>
			</template>
		</Dialog>
		<Dialog
			:dialog="isPaymentDetailDialogOpen"
			title="Payment Detail"
			current
			v-on:close="isPaymentDetailDialogOpen = false"
		>
			<template v-slot:title> Payment Detail </template>
			<template v-slot:body>
				<table v-if="singlePayment" width="100%" class="detail-table scattered-table">
					<tr>
						<th width="150px">Date</th>
						<td>
							<!-- <ShowValue :object="singlePayment" object-key="date" label="Date"> </ShowValue> -->
							{{ formatDate(singlePayment.date) }}
						</td>
						<th width="150px">Amount Received</th>
						<td>
							<ShowPrice :object="singlePayment" object-key="P_amount" label="Amount "></ShowPrice>
						</td>
					</tr>
					<tr>
						<th width="150px">status</th>
						<td>
							<Chip
								:text="singlePayment.status_value"
								:color="
									singlePayment.status_value === 'paid'
										? 'green'
										: singlePayment.status_value === 'partial-paid'
										? 'blue'
										: 'red'
								"
							>
							</Chip>
						</td>
					</tr>
					<tr>
						<th width="150px">Reference</th>
						<td>
							<ShowValue :object="singlePayment" object-key="reference" label="Reference"> </ShowValue>
						</td>
					</tr>
				</table>
			</template>
			<template v-slot:action>
				<v-btn
					depressed
					tile
					:disabled="pageLoading"
					v-on:click="
						isPaymentDetailDialogOpen = false;
						singlePayment = null;
					"
				>
					Close
				</v-btn>
			</template>
		</Dialog>
		<DeleteTemplate
			type="Payment"
			v-on:success="deletePaymentConfirm"
			v-on:close="(deleteDialog = false), (uuid = null)"
			:delete-dialog="deleteDialog"
			:deleteText="uuid"
			:deleteByUrl="false"
		>
		</DeleteTemplate>
	</div>
</template>
<script>
import ShowValue from "@/view/components/ShowValue.vue";
import ShowPrice from "@/view/components/ShowPrice.vue";
import ListingMixin from "@/core/mixins/listing.mixin";
import Dialog from "@/view/components/Dialog";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import Chip from "@/view/components/Chip";
import TextInput from "@/view/components/TextInput";
import DatePicker from "@/view/components/DatePicker";
import FileUpload from "@/view/components/FileUpload";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import {
	getPaymentByProject,
	getInvoiceByProject,
	createPayment,
	deletePayment,
} from "@/core/lib/project.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
export default {
	name: "payment-listing",
	title: "Payment Listing",
	mixins: [ListingMixin],
	components: {
		Dialog,
		ShowValue,
		TextInput,
		ShowPrice,
		DatePicker,
		FileUpload,
		Chip,
		AutoCompleteInput,
		DeleteTemplate,
	},
	data() {
		return {
			uuid: null,
			deleteDialog: false,
			isPaymentDetailDialogOpen: false,
			addDialog: false,
			payment: {
				date: null,
				documents: [],
				invoice: null,
				amount_received: null,
				reference: null,
			},
			singlePayment: null,
			paymentList: [],
			invoiceList: [],
		};
	},
	props: {
		project: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		openInvoiceDialog() {
			// this.addDialog = true;
			this.$router.push({
				name: "create-payment",
				params: { uuid: this.project.uuid },
				query: { t: new Date().getTime() },
			});
		},
		openDetailDialog(row) {
			this.singlePayment = row;
			this.isPaymentDetailDialogOpen = true;
		},

		openDeleteDialog(row) {
			this.deleteDialog = true;
			this.uuid = row.uuid;
		},
		async updateOrCreate() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.paymentForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.paymentForm.validate()) {
				return false;
			}

			const formData = {
				project: _this.project.id,
				date: _this.payment.date,
				amount: _this.payment.amount_received,
				documents: _this.payment.documents,
				invoice: _this.payment.invoice,
				reference: _this.payment.reference,
			};
			try {
				_this.pageLoading = true;
				await createPayment(formData);
				await this.getPaymentList();
				this.addDialog = false;
				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Payment has been created." },
				]);
				this.payment = {
					date: null,
					documents: [],
					invoice: null,
					amount_received: null,
					reference: null,
				};
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		async getPaymentList() {
			const _this = this;
			try {
				const { payments } = await getPaymentByProject(this.$route.params.uuid);
				this.paymentList = payments;
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		async deletePaymentConfirm() {
			try {
				this.pageLoading = true;
				await deletePayment(this.uuid);
				this.deleteDialog = false;
				this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Payment has been deleted." },
				]);
				this.uuid = null;
				await this.getPaymentList();
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
	},
	async mounted() {
		const { invoice } = await getInvoiceByProject(this.$route.params.uuid);
		await this.getPaymentList();
		this.invoiceList = invoice;
	},
};
</script>
