<template>
	<div>
		<v-layout class="min-height-57px">
			<v-flex class="font-level-3-bold my-auto d-flex justify-space-between w-100">
				<div class="detail-svg-icon mr-2 d-flex align-center">
					<!--begin::Svg Icon-->
					<inline-svg height="20" width="20" :src="$assetURL('media/custom-svg/truck-delivery.svg')" />
					<!--end::Svg Icon-->
					<template> <span class="my-auto ml-2">Deliverable Line Items</span> </template>
				</div>
			</v-flex>
		</v-layout>
		<div
			v-if="!pageLoading"
			class="overflow-y"
			style="max-height: calc(100vh - 337px); min-height: 200px"
		>
			<v-simple-table class="bt-table listing-table table-head-sticky">
				<thead>
					<tr>
						<th class="simple-table-th">QUOTATION #</th>
						<th class="simple-table-th">Name</th>
						<th class="simple-table-th">Price</th>
						<th class="simple-table-th">UOM</th>
						<th class="simple-table-th">QTY</th>
						<!-- <th class="simple-table-th">Delivered</th>
						<th class="simple-table-th">REJECTED</th>
						<th class="simple-table-th">Gap</th> -->
					</tr>
				</thead>
				<tbody v-if="lineItems.length">
					<tr class="cursor-pointer" v-for="(row, index) in lineItems" :key="index">
						<td class="td_column cursor-default simple-table-td">
							<Chip
								v-if="row"
								tooltip
								tooltip-text="Quotation #"
								:text="row.quotation_barcode"
								color="cyan"
							></Chip>
							<!-- <div class="mt-1">
								<Chip
									tooltip
									tooltip-text="Status"
									small
									:text="row.quotation_status_value"
									:color="row.quotation_status_value === 'approved' ? 'green' : 'cyan'"
								>
								</Chip>
							</div> -->
						</td>
						<td class="td_column cursor-default simple-table-td">
							<ShowValue class="mr-1" :object="row" object-key="line_item_name" label="Name"></ShowValue>
						</td>
						<td>
							<ShowPrice class="mr-1" :object="row" object-key="line_item_total" label="Price" />
						</td>
						<td class="td_column cursor-default simple-table-td">
							<ShowValue class="mr-1" :object="row" object-key="line_item_uom" label="UOM"></ShowValue>
						</td>
						<td class="td_column cursor-default simple-table-td">
							<span class="fw-400"> {{ row.line_item_quantity }} </span>
						</td>
						<!-- <td class="td_column cursor-default simple-table-td">
							<span class="fw-400"
								>{{ row.total_delivered_qty }}/{{ row.total_qty_to_be_delivered }}
							</span>
						</td>
						<td class="td_column cursor-default simple-table-td">
							<span class="fw-400">{{ row.total_returned_qty }} </span>
						</td>
						<td class="td_column cursor-default simple-table-td">
							<span class="fw-400">{{ row.gap }}/{{ row.total_delivered_qty }} </span>
						</td> -->
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="11">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no line items at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</v-simple-table>
		</div>
		<div v-else class="mx-20 my-40 text-center">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>
	</div>
</template>
<script>
import ShowValue from "@/view/components/ShowValue";
import ShowPrice from "@/view/components/ShowPrice";
import Chip from "@/view/components/Chip";
import { projectDeliverable } from "@/core/lib/project.lib";
import { SET_ERROR /* SET_MESSAGE */ } from "@/core/services/store/common.module";
export default {
	name: "lineItems-listing",
	title: "lineItems Listing",
	// mixins: [ListingMixin],
	data() {
		return {
			pageLoading: false,
			lineItems: [],
		};
	},

	components: {
		ShowPrice,
		ShowValue,
		Chip,
	},
	methods: {
		async initDeliverables() {
			try {
				this.pageLoading = true;
				const { deliver_summary } = await projectDeliverable(this.$route.params.uuid);
				this.lineItems = deliver_summary;
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
	},
	mounted() {
		this.internal = true;
		this.initDeliverables();
	},
};
</script>
