<template>
	<div>
		<div>&nbsp;</div>
		<div class="custom-page-header-count pt-0 px-0">
			<v-layout class="">
				<template>
					<template v-for="(data, index) in tasksStatus">
						<v-flex
							:key="index"
							class="py-0"
							:class="{
								'mr-1': index == 0,
								'ml-1': !tasksStatus[index + 1],
								'mx-1': index != 0 && tasksStatus[index + 1],
							}"
						>
							<div
								:class="
									'card m-0 top_card card-custom gutter-b border-' + data.color + ' lighten-5 ' + data.color
								"
								v-if="data.type == 'task'"
							>
								<div class="card-body p-0" @click="loadTasks(data.value)">
									<div class="mx-2 d-flex justify-space-between py-3">
										<div class="mt-4">
											<inline-svg
												style="width: 60px; height: 40px"
												fill="#baa9a9"
												:src="$assetURL('media/custom-svg/' + data.type + '.svg')"
											/>
										</div>
										<div class="d-flex flex-column justify-space-between">
											<h5 class="w-100 text-dark-55 font-weight-bold m-0" :class="`${data.color}--text`">
												{{ data.text }}
											</h5>

											<v-avatar
												v-if="data.text != 'All Tasks'"
												style="margin-left: auto"
												class="text-right text-white"
												size="35"
												:color="data.color"
												:text-color="data.textcolor"
												>{{ data.task_status_count }}</v-avatar
											>
											<v-avatar
												v-if="data.text == 'All Tasks'"
												style="margin-left: auto"
												class="text-right text-white"
												size="35"
												:color="data.color"
												:text-color="data.textcolor"
												>{{ data.all_tasks }}</v-avatar
											>
										</div>
									</div>
								</div>
							</div>
						</v-flex>
					</template>
				</template>
			</v-layout>
		</div>

		<v-layout class="min-height-57px">
			<v-flex class="font-level-3-bold my-auto d-flex justify-space-between w-100">
				<div class="detail-svg-icon mr-2 d-flex align-center">
					<!--begin::Svg Icon-->
					<inline-svg height="20" width="20" :src="$assetURL('media/custom-svg/task.svg')" />
					<!--end::Svg Icon-->
				</div>

				<template> <span class="my-auto">Tasks</span> </template>
				<v-btn @click="addTask" class="ml-auto" color="blue darken-4 white--text" tile depressed>
					<v-icon>mdi-plus</v-icon> Add Task</v-btn
				>
			</v-flex>
		</v-layout>
		<div
			v-if="!pageLoading"
			class="overflow-y taskListing"
			style="max-height: calc(100vh - 337px); min-height: 200px"
		>
			<v-simple-table class="bt-table listing-table table-head-sticky">
				<thead>
					<tr>
						<th class="simple-table-th">#</th>
						<th class="simple-table-th">Detail</th>
						<th class="simple-table-th">Status</th>
						<th class="simple-table-th">Team</th>
						<!-- <th max-width="150px" class="simple-table-th">Description</th> -->
						<th class="simple-table-th">Priority</th>
						<th class="simple-table-th">Reminder</th>
						<th class="simple-table-th">Date</th>
						<!-- <th class="simple-table-th">End Date</th> -->
						<th max-width="150px" class="simple-table-th">Tags</th>
						<th class="simple-table-th">Remark</th>
						<th class="simple-table-th">Actions</th>
					</tr>
				</thead>
				<tbody v-if="tasksList.length">
					<tr
						class="cursor-pointer"
						@click="openTask(row)"
						v-for="(row, index) in tasksList"
						:key="index"
					>
						<td class="td_column cursor-default simple-table-td">{{ index + 1 }}</td>
						<td class="td_column cursor-default simple-table-td">
							<div class="flex-wrap">
								<div>
									<span class="fw-500 mr-1">Name: </span>
									<ShowValue class="mr-1" :object="row" object-key="name" label="Task Name"></ShowValue>
								</div>
								<div>
									<span class="fw-500 mr-1">Description: </span>

									<span class="line-clamp-2" style="max-width: 200px">
										<ShowTextAreaValue :object="row" object-key="description" label="Description">
										</ShowTextAreaValue>
									</span>
								</div>
							</div>
						</td>
						<td width="120px" class="td_column cursor-default simple-table-td">
							<div v-on:click.stop class="d-flex justify-space-between align-center">
								<v-menu offset-y min-width="100px" style="padding: 0px 4px" rounded bottom>
									<template v-slot:activator="{ on, attrs }">
										<div
											v-bind="attrs"
											v-on="on"
											class="d-flex justify-space-between align-center"
											:style="taskStatusBorder(row)"
											style="padding: 0px 6px; background-color: #f7faff; width: 120px; border-radius: 9px"
										>
											<span class="text-capitalize font-level-1" :class="taskStatusColor(row)">{{
												row.status_value
											}}</span>
											<inline-svg
												fill="#808080"
												v-bind="attrs"
												v-on="on"
												:src="$assetURL('media/custom-svg/expand-more.svg')"
											/>
										</div>
									</template>

									<v-list elevation>
										<v-list-item
											:class="[row.status_value.toLowerCase() === item.toLowerCase() ? 'blue lighten-5' : '']"
											@click="changeTaskStatus(row, item)"
											class="task-list-item cursor-pointer p-1"
											v-for="(item, index) in statusList"
											:key="index"
										>
											<v-list-item-title class="pl-1">
												<v-avatar
													class="text-right text-10 text-white mr-2"
													size="25"
													:color="item == 'completed' ? 'green' : item == 'in-progress' ? 'blue' : 'red'"
													text-color="white"
												>
												</v-avatar
												>{{ item }}
											</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
							</div>
						</td>
						<td class="td_column cursor-default simple-table-td">
							<div class="d-flex align-items-center">
								<template v-if="row.team.length">
									<template v-for="(member, index) in row.team">
										<v-tooltip top content-class="custom-top-tooltip" :key="index + 4">
											<template v-slot:activator="{ on, attrs }">
												<v-avatar
													v-bind="attrs"
													v-on="on"
													v-if="index < 2"
													color="blue lighten-4"
													size="40"
													:style="`margin-left:${
														index > 0 ? '-12px' : ''
													};border:1px solid #0000002e !important`"
												>
													<template v-if="row && row.team_member_image">
														<v-img :src="row.team_member_image"></v-img>
													</template>
													<span v-else style="font-size: 14px" class="">
														{{ getTwoCharacter(member && member.team_member_name) }}
													</span>
												</v-avatar>
											</template>
											<span style="font-size: 11px">{{ member.team_member_name }}</span>
										</v-tooltip>
										<v-chip
											v-if="index == 2"
											:key="index"
											x-small
											label
											color="blue darken-4 white--text"
											class="px-1"
										>
											+{{ row.team.length - 2 }} more
										</v-chip>
									</template>
								</template>
								<template v-else>
									<span>
										<em class="text-muted">No Team Member</em>
									</span>
								</template>
							</div>
						</td>
						<!-- <td class="td_column cursor-default simple-table-td">
							<span class="line-clamp-2" style="max-width: 200px">
								<ShowTextAreaValue :object="row" object-key="description" label="description">
								</ShowTextAreaValue>
							</span>
						</td> -->
						<td max-width="100px" class="td_column cursor-default simple-table-td">
							<div v-on:click.stop>
								<v-menu offset-y style="min-width: 100px" round bottom>
									<template v-slot:activator="{ on, attrs }">
										<div v-bind="attrs" v-on="on" class="d-flex justify-space-between">
											<span class="text-capitalize font-level-1" :class="priorityColor(row)">{{
												priorityText(row)
											}}</span>
											<inline-svg
												fill="#808080"
												v-bind="attrs"
												v-on="on"
												:src="$assetURL('media/custom-svg/expand-more.svg')"
											/>
										</div>
									</template>
									<v-list elevation>
										<v-list-item
											:class="[
												row.priority_value.toLowerCase() === item.name.toLowerCase() ? 'blue lighten-5' : '',
											]"
											@click="changeTaskPriorityStatus(row, item)"
											class="task-list-item cursor-pointer p-1"
											v-for="(item, index) in priorityList"
											:key="index"
										>
											<v-list-item-title class="pl-1">
												<v-avatar
													class="text-right text-10 text-white mr-2"
													size="25"
													:color="item.id == 1 ? 'grey' : item.id == 2 ? 'blue' : 'red'"
													text-color="white"
												>
												</v-avatar>
												{{ item.name }}
											</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
							</div>
						</td>
						<td class="td_column cursor-default simple-table-td">
							<div class="flex-wrap">
								<div>
									<span class="fw-500 mr-1">Type:</span>
									<span class="fw-400" v-if="row.reminder_type_value">{{ row.reminder_type_value }}</span>
									<em v-else class="text-muted">No Type</em>
								</div>
								<div>
									<span class="fw-500 mr-1">Reminder Start Date:</span>
									<span class="fw-400" v-if="row.reminder_start_date">{{
										formatDate(row.reminder_start_date)
									}}</span>
									<em v-else class="text-muted">No Reminder Start Date</em>
								</div>
								<div>
									
									<span class="fw-500 mr-1">Reminder End Date:</span>
									<span class="fw-400" v-if="row.reminder_end_date">{{
										formatDate(row.reminder_end_date)
									}}</span>
									<em v-else class="text-muted">No Reminder End Date</em>
								</div>
								<div>
									<span class="fw-500 mr-1">Frequency:</span>
									<span class="fw-400" v-if="row.frequency">{{ row.frequency }}</span>
									<em v-else class="text-muted">No Frequency</em>
								</div>
							</div>
						</td>
						<td class="td_column cursor-default simple-table-td">
							<div class="flex-wrap">
								<div>
									<span class="fw-500 mr-1">Start Date:</span>
									<span class="fw-400" v-if="row.expected_start_date">{{
										formatDate(row.expected_start_date)
									}}</span>
									<em v-else class="text-muted">No Start Date</em>
								</div>

								<div>
									<span class="fw-500 mr-1">End Date:</span>
									<span class="fw-400" v-if="row.expected_end_date">{{
										formatDate(row.expected_end_date)
									}}</span>
									<em v-else class="text-muted">No End Date</em>
								</div>
							</div>
						</td>
						<!-- <td class="td_column cursor-default simple-table-td">
							<span v-if="row.expected_end_date">
								{{ formatDate(row.expected_end_date) }}
							</span>
							<em v-else class="text-muted">No End Date</em>
						</td> -->
						<td
							style="max-width: 150px; min-width: 150px"
							class="td_column cursor-default simple-table-td"
						>
							<div class="d-flex flex-wrap" v-if="row.tags.length">
								<template v-for="(item, tagIndex) in row.tags">
									<v-chip class="mb-2 mr-2" v-if="tagIndex < 3" :key="tagIndex" small tag outlined>{{
										item.tag
									}}</v-chip>
								</template>
								<span v-if="row.tags.length > 3">+({{ row.tags.length - 3 }} More)</span>
							</div>
							<div v-else>
								<span>
									<em class="text-muted">No tags</em>
								</span>
							</div>
						</td>
						<td width="150px" class="td_column cursor-default simple-table-td">
							<span class="line-clamp-2" style="max-width: 200px">
								<ShowTextAreaValue :object="row" object-key="remark" label="remark"> </ShowTextAreaValue>
							</span>
						</td>

						<td class="simple-table-td">
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<span v-bind="attrs" v-on="on">
										<v-btn
											@click.prevent.stop="deleteConfirm(row)"
											class="mx-2"
											color="red lighten-1"
											dark
											fab
											x-small
										>
											<v-icon small dark> mdi-trash-can-outline</v-icon>
										</v-btn>
									</span>
								</template>
								<span>Delete Task</span>
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<span v-bind="attrs" v-on="on">
										<v-btn
											class="mx-2"
											color="blue darken-4"
											dark
											fab
											x-small
											@click.prevent.stop="updateTask(row)"
										>
											<v-icon small dark> mdi-pencil</v-icon>
										</v-btn>
									</span>
								</template>
								<span>Update Task</span>
							</v-tooltip>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="11">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no task at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</v-simple-table>
		</div>
		<div v-else class="mx-20 my-40 text-center">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>

		<Dialog
			v-if="detailDialog"
			:dialog="detailDialog"
			title="Asset Stocks"
			current
			:dialog-width="900"
			v-on:close="detailDialog = false"
		>
			<template style="min-width: 100%" v-slot:title v-if="singleTaskDetail">
				<div>
					{{ singleTaskDetail.name
					}}<!--  #{{ singleTaskDetail.id }} -->
				</div>
				<v-spacer></v-spacer>
				<div class="d-flex">
					<v-menu offset-y style="padding: 0px 4px" rounded bottom>
						<template v-slot:activator="{ on, attrs }">
							<div
								v-bind="attrs"
								v-on="on"
								class="d-flex justify-space-between align-center"
								:style="taskStatusBorder(singleTaskDetail)"
								style="padding: 0px 6px; background-color: #f7faff; width: 120px; border-radius: 9px"
							>
								<span class="text-capitalize font-level-1" :class="taskStatusColor(singleTaskDetail)">{{
									singleTaskDetail.status_value
								}}</span>
								<inline-svg
									fill="#808080"
									v-bind="attrs"
									v-on="on"
									:src="$assetURL('media/custom-svg/expand-more.svg')"
								/>
							</div>
						</template>

						<v-list elevation>
							<v-list-item
								@click="changeTaskStatus(singleTaskDetail, item)"
								class="list-item cursor-pointer p-1"
								v-for="(item, index) in statusList"
								:key="index"
							>
								<v-list-item-title class="pl-1">
									<v-avatar
										class="text-right text-10 text-white mr-2"
										size="25"
										:color="item == 'completed' ? 'green' : item == 'in-progress' ? 'blue' : 'red'"
										text-color="white"
									>
									</v-avatar>
									{{ item }}
								</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
					<v-menu class="ml-4" offset-y style="min-width: 100px; padding: 0px 4px" round bottom>
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on" class="d-flex justify-space-between" 
							:style="taskPriorityBorder(singleTaskDetail)"
								style="padding: 0px 6px; background-color: #f7faff; width: 120px; border-radius: 9px"
								>
								<span class="text-capitalize font-level-1 ml-4" :class="priorityColor(singleTaskDetail)">{{
									priorityText(singleTaskDetail)
								}}</span>
								<inline-svg
									fill="#808080"
									v-bind="attrs"
									v-on="on"
									:src="$assetURL('media/custom-svg/expand-more.svg')"
								/>
							</div>
						</template>
						<v-list elevation>
							<v-list-item
								@click="changeTaskPriorityStatus(singleTaskDetail, item)"
								class="list-item cursor-pointer p-1"
								v-for="(item, index) in priorityList"
								:key="index"
							>
								<v-list-item-title class="pl-1">
									<v-avatar
										class="text-right text-10 text-white mr-2"
										size="25"
										:color="item.id == 1 ? 'grey' : item.id == 2 ? 'blue' : 'red'"
										text-color="white"
									>
									</v-avatar>
									{{ item.name }}
								</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</div>
			</template>
			<template v-slot:body>
				<v-tabs
					v-model="taskTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#overview">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon>
						Overview</v-tab
					>
					<v-tab href="#sub_task">
						<inline-svg
							style="width: 16px; height: 16px"
							class="mr-2"
							:fill="taskTab === 'sub_task' ? '#ffff' : '#0000008a'"
							:src="$assetURL('media/custom-svg/sub-task2.svg')"
						/>Sub Tasks</v-tab
					>
				</v-tabs>
				<v-tabs-items v-model="taskTab">
					<v-tab-item value="overview">
						<div class="overflow-y" style="max-height: calc(100vh - 250px)">
							<table width="100%" class="detail-table scattered-table" style="font-size: 13px">
								<tr>
									<th width="20%" max-width="100px">Name</th>
									<td>
										<ShowValue :object="singleTaskDetail" object-key="name" label="Task Name"> </ShowValue>
									</td>
								</tr>

								<tr>
									<th max-width="100px">Start Date</th>
									<td max-width="100px">
										<span class="fw-400" v-if="singleTaskDetail.expected_start_date">{{
											formatDate(singleTaskDetail.expected_start_date)
										}}</span>
										<em v-else class="text-muted">no start date</em>
									</td>
								</tr>

								<tr>
									<th max-width="100px">End Date</th>
									<td max-width="100px">
										<span class="fw-400" v-if="singleTaskDetail.expected_end_date">{{
											formatDate(singleTaskDetail.expected_end_date)
										}}</span>
										<em v-else class="text-muted">no end date</em>
									</td>
								</tr>

								<tr class="my-2" style="vertical-align: top">
									<th>Description</th>
									<td style="vertical-align: top">
										<div class="mb-2">
											<ShowValue :object="singleTaskDetail" object-key="description" label="Description">
											</ShowValue>
										</div>
									</td>
								</tr>
								<tr class="my-2">
									<th style="vertical-align: top">Tag</th>
									<td style="vertical-align: top">
										<div class="d-flex flex-wrap" v-if="singleTaskDetail.tags.length">
											<v-chip
												class="mb-2 mr-2"
												v-for="(item, index) in singleTaskDetail.tags"
												:key="index"
												small
												tag
												outlined
												>{{ item.tag }}</v-chip
											>
										</div>
										<div v-else>
											<span>
												<em class="text-muted">no tags</em>
											</span>
										</div>
									</td>
								</tr>
								<tr>
									<th style="vertical-align: top">Team Member</th>
									<td style="vertical-align: top">
										<div class="d-flex flex-wrap" v-if="singleTaskDetail.team.length">
											<v-chip
												class="mb-2 mr-2"
												v-for="(item, index) in singleTaskDetail.team"
												:key="index"
												small
												tag
												outlined
												>{{ item.team_member_name }}</v-chip
											>
										</div>
										<div v-else>
											<span>
												<em class="text-muted">no team</em>
											</span>
										</div>
									</td>
								</tr>
								<tr class="mt-2">
									<th width="20%" style="vertical-align: top">Remark</th>
									<td style="vertical-align: top">
										<ShowValue :object="singleTaskDetail" object-key="remark" label="Remark"> </ShowValue>
									</td>
								</tr>
							</table>
						</div>
					</v-tab-item>

					<v-tab-item value="sub_task">
						<SubTasksListing :task="singleTaskDetail" />
					</v-tab-item>
				</v-tabs-items>
			</template>
			<template v-slot:action>
				<v-btn depressed tile :disabled="pageLoading" v-on:click="detailDialog = false"> Close </v-btn>
			</template>
		</Dialog>
		<Dialog
			v-if="addTaskDialog"
			:dialog="addTaskDialog"
			title="Add Task"
			current
			v-on:close="addTaskDialog = false"
		>
			<template v-slot:title v-if="!uuid"> Add Task </template>
			<template v-slot:title v-else> Update Task </template>
			<template v-slot:body>
				<div class="min-height-280">
					<v-form
						v-if="addTaskDialog"
						class="taskForm"
						ref="taskForm"
						lazy-validation
						v-on:submit.stop.prevent="updateOrCreate()"
					>
						<v-row>
							<v-col md="3" class="py-0">
								<label for="name" class="btx-label mt-4 required">Name</label>
							</v-col>
							<v-col md="9" class="py-0">
								<TextInput
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									id="name"
									placeholder="Name"
									v-model="task.name"
									:rules="[vrules.required(task.name, 'Name')]"
									:class="{
										required: !task.name,
									}"
								>
								</TextInput>
							</v-col>
							<v-col md="3" class="my-auto py-0">
								<label for="priority" class="btx-label mt-4">Priority</label>
							</v-col>
							<v-col md="9" class="py-0">
								<AutoCompleteInput
									hide-details
									:items="priorityList"
									:disabled="pageLoading"
									item-text="name"
									item-value="id"
									:loading="pageLoading"
									id="priority"
									placeholder="priority"
									v-model="task.priority"
								>
								</AutoCompleteInput>
							</v-col>

							<v-col md="3" class="py-0">
								<label for="description" class="btx-label mt-4">Description</label>
							</v-col>

							<v-col md="9" class="py-0">
								<TextAreaInput
									:disabled="pageLoading"
									:loading="pageLoading"
									id="description"
									placeholder="Description"
									v-model="task.description"
								>
								</TextAreaInput>
							</v-col>
							<v-col md="3" class="py-0">
								<label for="team_members" class="btx-label mt-4">Team</label>
							</v-col>
							<v-col md="9" class="py-0">
								<TeamMembers
									hide-details
									dense
									v-model="task.team"
									:items="teamMembersList"
									:disabled="pageLoading"
									:loading="pageLoading"
									id="team_members"
									placeholder="Team Members"
									@remove="remove"
									multiple
									:selectionChip="2"
								/>
							</v-col>
							<v-col md="3" class="my-auto py-0">
								<label for="task_start_date" class="btx-label mt-4">Start Date</label>
							</v-col>
							<v-col md="3" class="py-0">
								<DatePicker
									:minDate="project.start_date"
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									id="task_start_date"
									v-model="task.start_date"
								></DatePicker>
							</v-col>
							<v-col md="3" class="my-auto py-0 text-right">
								<label for="task_till_date" class="btx-label mt-4">Till Date</label>
							</v-col>
							<v-col md="3" class="py-0">
								<DatePicker
									:minDate="task.start_date"
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									id="task_till_date"
									v-model="task.end_date"
								></DatePicker>
							</v-col>
							<v-col md="3" class="my-auto py-0">
								<label for="reminder-type" class="btx-label mt-4">Reminder Type</label>
							</v-col>
							<v-col md="9" class="py-0">
								<AutoCompleteInput
									hide-details
									:items="remindertypeList"
									:disabled="pageLoading"
									item-text="text"
									item-value="value"
									@change="reminderTypeChange"
									:loading="pageLoading"
									id="reminder-type"
									placeholder="reminder"
									v-model="task.reminder_type"
								>
								</AutoCompleteInput>
							</v-col>
							<v-col md="3" class="my-auto py-0">
								<label for="frequency" class="btx-label mt-4">Frequency</label>
							</v-col>
							<v-col md="9" class="py-0">
								<TextInput
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									id="frequency"
									@keyup="frequencyChange"
									placeholder="Frequency"
									v-model.number="task.frequency"
								></TextInput>
							</v-col>
							<v-col md="3" class="my-auto py-0">
								<label for="reminder_start_date" class="btx-label mt-4">Reminder Start Date</label>
							</v-col>
							<v-col md="3" class="py-0">
								<DatePicker
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									@change="reminderTypeChange($event,'date')"
									id="reminder_start_date"
									v-model="task.reminder_start_date"
								>
								</DatePicker>
							</v-col>
							<template  v-if="show_reminder_end_date">
								<v-col md="3" class="my-auto py-0 text-right">
									<label for="reminder_end_date" class="btx-label mt-4">Reminder End Date</label>
								</v-col>
								<v-col md="3" class="py-0">
									<DatePicker
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="reminder_end_date"
										@change="endDateChange($event)"
										v-model="task.reminder_end_date"
									>
									</DatePicker>
								</v-col>
							</template>
							<template  v-else>
								<v-col md="3" class="my-auto py-0 text-right">
									
								</v-col>
								<v-col md="3" class="py-0">
									
								</v-col>
							</template>

							<v-col md="3" class="my-auto py-0">
								<label for="tags" class="btx-label mt-4">Tags</label>
							</v-col>
							<v-col md="9" class="py-0 mt-3">
								<v-combobox
									id="tags"
									hide-details
									v-model="model"
									class="combobox p-1"
									:filter="filterTag"
									:hide-no-data="!search"
									outlined
									:items="items"
									:search-input.sync="search"
									item-text="tag"
									item-value="id"
									hide-selected
									multiple
									small-chips
									dense
									placeholder="Search Tag"
								>
									<template v-slot:no-data>
										<v-chip label class="my-2 mx-2" small>
											{{ search }}
										</v-chip>
									</template>
									<template v-slot:selection="{ attrs, item, parent, selected }">
										<v-chip
											class="my-1"
											v-if="item === Object(item)"
											v-bind="attrs"
											:input-value="selected"
											label
											small
										>
											<span class="pr-2">
												{{ item.tag }}
											</span>
											<v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
										</v-chip>
									</template>
									<v-list-item class="my-2 white" v-slot:item="{ item }">
										<v-list-item-content>
											<v-list-item-title>
												<v-chip class="mb-1" label small>
													{{ item.tag }}
												</v-chip>
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-combobox>
							</v-col>
							<v-col md="3" class="py-0">
								<label for="remark" class="btx-label mt-4">Remark</label>
							</v-col>
							<v-col md="9" class="py-0">
								<TextAreaInput
									:disabled="pageLoading"
									:loading="pageLoading"
									id="remark"
									placeholder="Remark"
									v-model="task.remark"
								></TextAreaInput> </v-col
						></v-row>
					</v-form>
				</div>
			</template>
			<template v-slot:action>
				<v-btn depressed tile :disabled="pageLoading" @click="closeAddDialog"> Close </v-btn>
				<v-btn
					class="white--text"
					depressed
					color="blue darken-4"
					tile
					@click.prevent.stop="updateOrCreate()"
				>
					Save
				</v-btn>
			</template>
		</Dialog>
		<Dialog :dialog="deleteDialog" :dialog-width="640">
			<template v-slot:title> Delete Task</template>
			<template v-slot:body>
				<v-row class="delete-dialog">
					<v-col md="2" class="py-0 text-right my-auto">
						<span class="svg-icon svg-icon-lg delete-confirm-icon">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
							<!--end::Svg Icon-->
						</span>
					</v-col>
					<v-col md="10" class="py-0 my-auto">
						<p class="btx-p m-0">
							<span class="text-capitalize">Task</span>
							<span class="red--text font-bolder lighten-1"> {{ deleteName }} </span>
							will be deleted forever and cannot be retrieved later. Are you sure about deleting it?
						</p>
					</v-col>
					<!-- <v-col v-if="deleteNote" md="12" class="pb-0 mt-2 my-auto">
						<em>Note: {{ deleteNote }}</em>
					</v-col> -->
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn class="white--text" @click="deleteTask" depressed color="red lighten-1" tile>
					Yes! Delete
				</v-btn>
				<v-btn depressed tile @click="deleteDialog = false"> No, Close </v-btn>
			</template>
		</Dialog>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import ShowValue from "@/view/components/ShowValue.vue";
import ShowTextAreaValue from "@/view/components/ShowTextAreaValue.vue";
import ListingMixin from "@/core/mixins/listing.mixin";
import Dialog from "@/view/components/Dialog";
import SubTasksListing from "@/view/components/SubTasksListing";
import {
	CreateTask,
	getTaskOfProject,
	deleteTasks,
	updateTask,
	updateTaskStatus,
	updateTaskPriorityStatus,
} from "@/core/lib/project.lib";
import DatePicker from "@/view/components/DatePicker";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import TeamMembers from "@/view/components/TeamMembers";
import { cloneDeep } from "lodash";
// import { map } from "lodash";
import MomentJS from "moment-timezone";

export default {
	name: "task-listing",
	title: "Task Listing",
	mixins: [ListingMixin],
	components: {
		Dialog,
		ShowValue,
		SubTasksListing,
		TextInput,
		TextAreaInput,
		DatePicker,
		AutoCompleteInput,
		TeamMembers,
		ShowTextAreaValue,
	},
	data() {
		return {
			deleteUUID: null,
			taskTab: 'overview',
			uuid: null,
			pageTitle: "Issues",
			addTaskDialog: false,
			detailDialog: false,
			endpoint: "reservation",
			defaultFilter: { status: "checked-out" },
			deleteDialog: false,
			deleteName: null,
			model: [],
			search: null,
			teamMembersList: [],
			tasksList: [],
			priorityList: [],
			statusList: ["completed", "in-progress", "pending"],
			remindertypeList: [],
			reminderTypes: [],
			show_reminder_end_date:false,
			task: {
				name: null,
				priority: null,
				description: null,
				team: [],
				reminder_type: null,
				reminder_type_value: null,
				reminder_start_date: null, //MomentJS().format("YYYY-MM-DD")
				reminder_end_date: null,
				frequency: null,
				start_date: null,
				end_date: null,
				remark: null,
			},
			singleTaskDetail: null,
			editing: null,
			editingIndex: -1,
			items: [],
			tasksStatus: [],
		};
	},
	props: {
		project: {
			type: Object,
			default: () => {},
		},
	},
	watch: {
		model(val, prev) {
			if (val.length === prev.length) return;

			this.model = val.map((v) => {
				if (typeof v === "string") {
					v = {
						tag: v,
					};
					this.items.push(v);
				}

				return v;
			});
		},
		addTaskDialog() {
			this.reArrangeTeamListArray();
		},
	},

	computed: {
		...mapGetters(["errors", "localDB"]),
		typeValue() {
			const data = this.remindertypeList.filter((ele) => ele.value == this.task.reminder_type);
			//console.log(data);
			return data[0] ? data[0].text : null;
		},
		priorityText: function () {
			return function name(row) {
				return row.priority === 1 ? "low" : row.priority === 2 ? "medium" : "high";
			};
		},
		priorityColor: function () {
			return function name(row) {
				return row.priority === 1
					? "grey--text lighten-1"
					: row.priority === 2
					? "blue--text"
					: "red--text";
			};
		},
		taskStatusColor: function () {
			return function name(row) {
				return row.status_value === "completed"
					? "green--text"
					: row.status_value === "in-progress"
					? "blue--text"
					: "red--text";
			};
		},
		taskStatusBorder: function () {
			return function name(row) {
				return row.status_value === "completed"
					? "border:1px solid #4CAF50"
					: row.status_value === "in-progress"
					? "border:1px solid #2196F3"
					: "border:1px solid #F44336";
			};
		},
		taskPriorityBorder: function () {
			return function name(row) {
				return row.priority === "low"
					? "border:1px solid #9e9e9e"
					: row.priority === "high"
					? "border:1px solid #F44336"
					: "border:1px solid #2196F3";
			};
		},
	},
	methods: {
		frequencyChange() {
			
			let start_date = MomentJS(this.task.reminder_start_date);
			if (this.task.reminder_type === 1) {
				this.task.reminder_end_date = null;
				this.show_reminder_end_date = false;
			} else if (this.task.reminder_type === 2) {
				const days = start_date.add(this.task.frequency, "days");
				this.show_reminder_end_date = true;
				this.task.reminder_end_date = days.format("YYYY-MM-DD");
			} else if (this.task.reminder_type === 3) {
				const days = start_date.add(this.task.frequency * 7, "days");
				this.show_reminder_end_date = true;
				this.task.reminder_end_date = days.format("YYYY-MM-DD");
			} else if (this.task.reminder_type === 4) {
				const days = start_date.add(this.task.frequency, "months");
				this.show_reminder_end_date = true;
				this.task.reminder_end_date = days.format("YYYY-MM-DD");
			} else if (this.task.reminder_type === 5) {
				const days = start_date.add(this.task.frequency * 3, "months");
				this.show_reminder_end_date = true;
				this.task.reminder_end_date = days.format("YYYY-MM-DD");
			} else if (this.task.reminder_type === 6) {
				const days = start_date.add(this.task.frequency * 6, "months");
				this.show_reminder_end_date = true;
				this.task.reminder_end_date = days.format("YYYY-MM-DD");
			}
		},
		reminderTypeChange(event,eventName='') {
		
			if(this.task.reminder_start_date==null){
				this.task.reminder_start_date = MomentJS().format("YYYY-MM-DD");
			}
			let start_date = MomentJS(this.task.reminder_start_date);
			if(eventName=='date' && event!=''){
				start_date = MomentJS(event);
				//console.log(start_date);
				this.task.reminder_start_date = start_date.format('YYYY-MM-DD');
				//console.log(this.task.reminder_start_date );
				
			}
			this.task.frequency = this.task.frequency ? this.task.frequency : 1;
			
			if (this.task.reminder_type === 1) {
				this.task.frequency = null;
				this.show_reminder_end_date = false;
				this.task.reminder_end_date = null;
			} else if (this.task.reminder_type === 2) {
				this.task.frequency = 1;
				const days = start_date.add(this.task.frequency, "days");
				this.show_reminder_end_date = true;
				this.task.reminder_end_date = days.format("YYYY-MM-DD");
			} else if (this.task.reminder_type === 3) {
				// this.task.frequency = 1;
				const days = start_date.add(this.task.frequency * 7, "days");
				this.show_reminder_end_date = true;
				this.task.reminder_end_date = days.format("YYYY-MM-DD");
			} else if (this.task.reminder_type === 4) {
				const days = start_date.add(this.task.frequency, "months");
				this.show_reminder_end_date = true;
				this.task.reminder_end_date = days.format("YYYY-MM-DD");
				// this.task.frequency = 1;
			} else if (this.task.reminder_type === 5) {
				const days = start_date.add(this.task.frequency * 3, "months");
				this.show_reminder_end_date = true;
				this.task.reminder_end_date = days.format("YYYY-MM-DD");
				// this.task.frequency = 1;
			} else if (this.task.reminder_type === 6) {
				const days = start_date.add(this.task.frequency * 6, "months");
				this.show_reminder_end_date = true;
				this.task.reminder_end_date = days.format("YYYY-MM-DD");
				// this.task.frequency = 1;
			}
		},

		endDateChange(event) {
			// if(this.task.reminder_start_date==null){
			// 	this.task.reminder_start_date = MomentJS().format("YYYY-MM-DD");
			// }
			let end_date = MomentJS(event);
			// if(eventName=='date' && event!=''){
			// 	start_date = MomentJS(event);
			// 	//console.log(start_date);
			// 	this.task.reminder_start_date = start_date.format('YYYY-MM-DD');
			// 	//console.log(this.task.reminder_start_date );
				
			// }
			this.task.frequency = this.task.frequency ? this.task.frequency : 1;
			//console.log(this.task.frequency);
			if (this.task.reminder_type === 1) {
				this.task.reminder_start_date =  MomentJS().format("YYYY-MM-DD");
				this.task.frequency = null;
				this.show_reminder_end_date = false;
				this.task.reminder_end_date = null;
			} else if (this.task.reminder_type === 2) {
				this.task.frequency = 1;
				const days = end_date.subtract(this.task.frequency, "days");
				//this.show_reminder_end_date = true;
				this.task.reminder_start_date = days.format("YYYY-MM-DD");
			} else if (this.task.reminder_type === 3) {
				// this.task.frequency = 1;
				const days = end_date.subtract(this.task.frequency * 7, "days");
				//this.show_reminder_end_date = true;
				this.task.reminder_start_date = days.format("YYYY-MM-DD");
			} else if (this.task.reminder_type === 4) {
				const days = end_date.subtract(this.task.frequency, "months");
				//this.show_reminder_end_date = true;
				this.task.reminder_start_date = days.format("YYYY-MM-DD");
				// this.task.frequency = 1;
			} else if (this.task.reminder_type === 5) {
				const days = end_date.subtract(this.task.frequency * 3, "months");
				//this.show_reminder_end_date = true;
				this.task.reminder_start_date = days.format("YYYY-MM-DD");
				// this.task.frequency = 1;
			} else if (this.task.reminder_type === 6) {
				const days = end_date.subtract(this.task.frequency * 6, "months");
				//this.show_reminder_end_date = true;
				this.task.reminder_start_date = days.format("YYYY-MM-DD");
				// this.task.frequency = 1;
			}
		},

		async updateOrCreate() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.taskForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.taskForm.validate()) {
				return false;
			}
			
			try {
				const formData = {
					type: "project",
					priority: this.task.priority,
					parent: _this.project.id,
					name: _this.task.name,
					description: _this.task.description,
					team: this.task.team,
					tags: this.model,
					reminder_type_value:this.typeValue,
					reminder_start_date: this.task.reminder_start_date,
					reminder_end_date: this.task.reminder_end_date,
					reminder_type: this.task.reminder_type,
					frequency: this.task.frequency,
					expected_start_date: _this.task.start_date,
					expected_end_date: _this.task.end_date,
					remark: _this.task.remark,
				};
				if(formData.reminder_start_date>formData.reminder_end_date){
					_this.$store.commit(SET_ERROR, [{ model: true, message: "Reminder Start Date can not be greater than End Date" }]);
					return false;
				}
				
				_this.pageLoading = true;
				if (_this.uuid) {
					await updateTask(_this.uuid, formData);
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Task has been updated." },
					]);
					_this.uuid = null;
					// this.showMoreArray.push(false)
				} else {
					await CreateTask(formData);

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Task has been created." },
					]);
				}
				_this.loadTasks();
				this.$emit("refreshGetProjectAPI");
				_this.addTaskDialog = false;
				_this.task = {
					name: null,
					description: null,
					priority: null,
					tags: null,
					team: [],
					frequency: null,
					reminder_type_value: null,
					reminder_start_date: null,
					reminder_end_date: null,
					expected_start_date: null,
					start_date: null,
					end_date: null,
					remark: null,
				};
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		edit(index, item) {
			if (!this.editing) {
				this.editing = item;
				this.editingIndex = index;
			} else {
				this.editing = null;
				this.editingIndex = -1;
			}
		},
		filterTag(item, queryText, itemText) {
			if (item.header) return false;
			const hasValue = (val) => (val != null ? val : "");

			const text = hasValue(itemText);
			const query = hasValue(queryText);

			return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1;
		},
		async deleteTask() {
			let _this = this;
			try {
				const params = {
					tasks: [this.deleteUUID],
				};
				await deleteTasks(params);
				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Task has been deleted." },
				]);
				this.loadTasks();
				this.deleteDialog = false;
				this.deleteUUID = null;
				this.deleteName = null;
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			}
		},

		openTask(task) {
			this.detailDialog = true;
			this.singleTaskDetail = task;
			this.taskTab ='overview';
		},
		deleteConfirm(task) {
			this.deleteDialog = true;
			this.deleteUUID = task.uuid;
			this.deleteName = task.name;
		},
		addTask() {
			this.addTaskDialog = true;
		},
		async loadTasks(status='all') {
			try {
				this.pageLoading = true;
				const { tasks, tasks_status } = await getTaskOfProject(this.$route.params.uuid,status);
				this.tasksList = tasks;
				this.tasksStatus = tasks_status;
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		updateTask(task) {
			this.addTaskDialog = true;
			this.task.name = task["name"];
			this.task.priority = task["priority"];
			this.task.description = task["description"];
			if (task.team.length > 0) {
				const team = task.team.map((ele) => {
					return ele.user;
				});
				this.task.team = team;
			}
			if (task["tags"].length > 0) {
				this.model = cloneDeep(task["tags"]);
				this.items = cloneDeep(task["tags"]);
			}
			this.task.reminder_type_value = task["reminder_type_value_value"];
			this.task.reminder_start_date = task["reminder_start_date"];
			this.task.reminder_end_date = task["reminder_end_date"];
			this.task.frequency = task["frequency"];
			this.task.tags = task["tags"];
			this.task.start_date = task["expected_start_date"];
			this.task.end_date = task["expected_end_date"];
			this.task.remark = task["remark"];
			this.task.reminder_type = task["reminder_type"];
			this.uuid = task.uuid;
		},
		closeAddDialog() {
			this.addTaskDialog = false;
			this.task = {
				name: null,
				priority: null,
				description: null,
				team: [],
				tags: null,
				reminder_type: null,
				reminder_start_date: null,
				reminder_end_date: null,
				frequency: null,
				start_date: null,
				end_date: null,
				remark: null,
			};
			this.uuid = null;
			this.model = [];
			this.items = [];
		},
		remove(item) {
			this.task.team = item;
		},
		getTwoCharacter(word) {
			if (word) {
				return word.slice(0, 2).toUpperCase();
			}
			return "";
		},
		async changeTaskStatus(task, status) {
			const _this = this;
			try {
				_this.pageLoading = true;
				await updateTaskStatus(status, task.uuid);
				this.detailDialog = false;
				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Task Status has been update." },
				]);
				await _this.loadTasks();
				this.$emit("refreshGetProjectAPI");
				this.uuid = null;
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		async changeTaskPriorityStatus(task, priority) {
			const _this = this;
			try {
				_this.pageLoading = true;
				await updateTaskPriorityStatus(priority.name.toLowerCase(), task.uuid);
				this.detailDialog = false;
				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Task Priority has been update." },
				]);
				await _this.loadTasks();
				this.uuid = null;
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		async reArrangeTeamListArray() {
			if (!this.uuid) {
				// if (this.project.team_members.length > 0) {
				// 	const array = this.project.team_members.map((ele) => {
				// 		return ele.id;
				// 	});
				// 	console.log(array);
				// 	//this.task.team = array;
				// }

				for (let index = 0; index < this.teamMembersList.length; index++) {
					for (let j = 0; j < this.project.team_members.length; j++) {
						if (this.teamMembersList[index].value === this.project.team_members[j].id) {
							const item = this.teamMembersList[index];
							this.teamMembersList.splice(index, 1);
							this.teamMembersList.unshift(item);
						}
					}
				}
			} else {
				for (let index = 0; index < this.teamMembersList.length; index++) {
					for (let j = 0; j < this.task.team.length; j++) {
						if (this.teamMembersList[index].value === this.task.team[j]) {
							const item = this.teamMembersList[index];
							this.teamMembersList.splice(index, 1);
							this.teamMembersList.unshift(item);
						}
					}
				}
			}
		},
	},

	beforeMount() {
		// this.init();
	},
	async mounted() {
		this.internal = true;
		await this.loadTasks();
		await this.$store.dispatch(SET_LOCAL_DB).then(() => {
			this.teamMembersList = this.localDB("members", []);
			this.priorityList = this.localDB("priority", []);
			const reminder_type = this.localDB("reminder_types", []);
			this.remindertypeList = reminder_type;
		});
		this.reArrangeTeamListArray();
	},
};
</script>

<style scoped>
.task_row:hover {
	background-color: rgb(213, 213, 213, 0.2);
}
</style>
