<template>
	<div v-if="!pageLoading" class="w-100 quotation-line-items overflow-y custom-table-height">
		<div class="d-flex justify-end align-center my-3">
			<v-btn
				@click="saveEquipment(line_items)"
				class="white--text"
				depressed
				color="blue darken-4"
				tile
				:disabled="projectStatus==3"
			>
				Save
			</v-btn>
		</div>
		<table width="100%" style="table-layout: fixed" >
			<thead>
				<tr>
					<th class="pa-2 simple-table-th" width="40"></th>
					<th class="pa-2 simple-table-th text-center" width="40">#</th>
					<th class="pa-2 simple-table-th" width="200">Product</th>
					<!-- <th class="pa-2 simple-table-th" width="350">Description</th> -->
					<th class="pa-2 simple-table-th" width="120">Tolerance(%)</th>
					<th class="pa-2 simple-table-th" width="80">UOM</th>
					<th class="pa-2 simple-table-th" width="100">Type</th>
					<th class="pa-2 simple-table-th" width="80">Qty</th>
					<th class="pa-2 simple-table-th text-right" width="120">Rate</th>
					<th class="pa-2 simple-table-th text-right" width="120">Amount</th>
					<th class="pa-2 simple-table-th text-right" width="80"></th>
				</tr>
			</thead>
			<!-- <tbody > -->
				
			<Draggable
				tag="tbody"
				v-model="line_items"
				class="draggable-group"
				handle=".draggable-drag-icon"
				v-clickoutside="outsideClicked"
				v-on:change="updateItemOrder($event)"
			>
				<tr v-for="(row, index) in line_items" :key="index" class="">
					<td class="py-1 px-2">
						<v-icon class="draggable-drag-icon cursor-move" color="blue darken-4">mdi-drag</v-icon>
					</td>
					<td class="py-1 px-2 text-center">{{ index + 1 }}</td>
					<td
						class="py-1 px-2"
						@click="editRow($event, index)"
						:class="row.isEditable ? 'px-2' : 'px-3'"
					>
						<AutoCompleteInput
							v-if="row.isEditable"
							item-text="name"
							item-value="id"
							hide-details
							:items="productList"
							:disabled="pageLoading"
							:loading="pageLoading"
							:id="`product-${uniqFieldId}`"
							placeholder="Product"
							v-model="row.product_service_id"
						></AutoCompleteInput>
						<template v-else>{{ getProductName(row.product_service_id) }}</template>
					</td>
					<!-- <td
						style="max-width: 300px"
						class="py-1 px-2 text-truncate"
						@click="editRow($event, index)"
						:class="row.isEditable ? 'px-2' : 'px-3'"
					>
						<TextInput
							v-if="row.isEditable"
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							v-model="row.budget_items_description"
							class="mt-0"
						></TextInput>
						<template v-else>{{ row.budget_items_description }}</template>
					</td> -->
					<td
						style="max-width: 300px"
						class="py-1 px-2 text-truncate"
						@click="editRow($event, index)"
						:class="row.isEditable ? 'px-2' : 'px-3'"
					>
						5 %
					</td>
					<td
						class="py-1 px-2"
						@click="editRow($event, index)"
						:class="row.isEditable ? 'px-2' : 'px-3'"
					>
						<SelectUnit
							v-if="row.isEditable"
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							id="uom"
							hide-top-margin
							placeholder="uom"
							class="mt-0"
							v-model="row.budget_items_uom"
							v-on:change="changeClicked()"
						></SelectUnit>
						<template v-else>{{ row.budget_items_uom }}</template>
					</td>
					<td
						class="py-1 px-2"
						@click="editRow($event, index)"
						:class="row.isEditable ? 'px-2' : 'px-3'"
					>
						<AutoCompleteInput
							v-if="row.isEditable"
							item-text="text"
							item-value="value"
							hide-details
							:items="typeList"
							:disabled="pageLoading"
							:loading="pageLoading"
							class="mt-0"
							placeholder="type"
							v-model="row.budget_type"
						></AutoCompleteInput>
						<template v-else>
							<span class="text-capitalize">
								{{ getBudgetName(row.budget_type) }}
							</span>
						</template>
					</td>

					<td
						class="py-1 px-2"
						@click="editRow($event, index)"
						:class="row.isEditable ? 'px-2' : 'px-3'"
					>
						<QuantityInput
							v-if="row.isEditable"
							hide-details
							:disabled="qtyDisabled(row.type) || pageLoading"
							:loading="pageLoading"
							v-model.number="row.budget_items_quantity"
							class="mt-0 px-0 text-right"
							hide-spin-buttons
						></QuantityInput>
						<template v-else>{{ row.budget_items_quantity }}</template>
					</td>
					<td
						class="py-1 px-2 text-right"
						@click="editRow($event, index)"
						:class="row.isEditable ? 'px-2' : 'px-3'"
					>
						<PriceInput
							v-if="row.isEditable"
							hide-details
							:loading="pageLoading"
							:disabled="rateDisable(row.type) || pageLoading"
							v-model.number="row.budget_items_rate"
							reverse
							class="mt-0"
							type="number"
							hide-spin-buttons
							v-on:keydown.tab.prevent="tabClicked($event, index)"
						></PriceInput>
						<template v-else>{{ formatMoney(row.budget_items_rate) }}</template>
					</td>
					<td
						class="py-1 px-2 text-right"
						@click="editRow($event, index)"
						:class="row.isEditable ? 'px-2' : 'px-3'"
					>
					
						<template>{{ formatMoney(row.budget_items_rate*row.budget_items_quantity) }}</template>
					</td>
					<td class="py-1 px-2 text-right">
						<v-icon
							title="Product"
							color="red"
							:disabled="line_items.length == 1 ? true : false"
							class="cursor-pointer"
							@click="deleteLineItems(index, row.id)"
							>mdi-delete-outline</v-icon
						>
					</td>
				</tr>
				<tr>
					<td class="pa-2 borer-none" colspan="5">
						<v-btn @click="addLineItems()" class="white--text" depressed color="blue darken-4" tile>
							<v-icon left>mdi-plus</v-icon>
							Add Product
						</v-btn>
					</td>
				</tr>
			</Draggable>
			<!-- </tbody> -->
		</table>

		<Dialog
			:dialog="deleteConfirmationDialog"
			v-on:close="deleteConfirmationDialog = false"
			v-if="deleteConfirmationDialog"
			:dialog-width="500"
		>
			<template v-slot:title> Are you sure? </template>
			<template v-slot:body>
				<v-row>
					<v-col md="12" class="py-0">
						<p class="mt-0" style="line-height: 18px">
							Are you sure want to delete line item?
							<br />
							Line item can not be recovered once deleted?
						</p>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					:disabled="deleteConfirmationLoading"
					depressed
					tile
					@click="deleteConfirmationDialog = false"
				>
					NO</v-btn
				>
				<v-btn
					:disabled="deleteConfirmationLoading"
					:loading="deleteConfirmationLoading"
					class="white--text"
					depressed
					color="blue darken-4"
					tile
					@click="yesDeleteLineItem()"
				>
					YES
				</v-btn>
			</template>
		</Dialog>
	</div>
</template>
<script>
// import TextInput from "@/view/components/TextInput";
import SelectUnit from "@/view/components/SelectUnit";
import PriceInput from "@/view/components/PriceInput";
import Dialog from "@/view/components/Dialog";
import QuantityInput from "@/view/components/QuantityInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import Draggable from "vuedraggable";
import { GetAllProduct } from "@/core/lib/common.lib";
import { DeletePOLineItem } from "@/core/lib/purchase-order.lib";
import { find } from "lodash";
import "@/core/event-bus/click-outside";
import { GetProjectBudgetLineItems, createEquipment } from "@/core/lib/project-transfer.lib.js";
import { SET_ERROR,SET_MESSAGE } from "@/core/services/store/common.module";

export default {
	name: "po-line-items",
	model: {
		event: "input",
	},
	props: {
		projectStatus:{
			type: Number,
			default: 1,
		}
	},
	data() {
		return {
			tax_applied: true,
			pageLoading: false,
			deleteConfirmationDialog: false,
			deleteConfirmationLoading: false,
			createProductDialog: false,
			deleteId: null,
			deleteIndex: -1,
			isOutside: true,
			line_items: [
				{
					isEditable: false,
					product: null,
					description: null,
					quantity: 1,
					order: 1,
					uom: null,
					rate: 0,
					total: 0,
					budget_type:3,
				},
			],
			taxList: [8],
			tax_value: 8,
			taxable_amount: 0,
			tax_amount: 0,
			discount_value: 0,
			discount_amount: 0,
			discount_value_type: 1,
			discount_type: 1,
			adjustment: 0,
			adjustment_amount: 0,
			sub_total: 0,
			total: 0,
			discountTypeList: [
				{ text: "$", value: 1 },
				{ text: "%", value: 2 },
			],
			typeList: [
				{
					text: "Price",
					value: 2,
				},
				{
					text: "Quantity",
					value: 1,
				},
				{
					text: "Both",
					value: 3,
				},
				{
					text: "None",
					value: 4,
				},
			],
			productList: [],
		};
	},
	computed: {
		qtyDisabled() {
			return (type) => {
				if (type == "price" || type == "none") {
					return true;
				}
				return false;
			};
		},
		rateDisable() {
			return (type) => {
				if (type == "quantity" || type == "none") {
					return true;
				}
				return false;
			};
		},
	},
	methods: {
		outsideClicked() {
			if (!this.isOutside) {
				const _line_items = this.line_items.map((row) => {
					return { ...row, isEditable: false };
				});
				this.line_items = _line_items;
				this.isOutside = true;
			}
		},
		async saveEquipment(equipments) {
			
			const filterLineItem = equipments.filter((equipment) => equipment.product_service_id);
			const equipmentsLineItem = filterLineItem.map((equipment) => {
				if (equipment.product_service_id) {
					return {
						uuid: equipment.budget_uuid || null,
						line_item: equipment.budget_items_line_item||0,
						item_id: equipment.product_service_id || null,
						description: equipment.budget_items_description || null,
						quantity: equipment.budget_items_quantity || 0,
						quantity_type: null,
						discount: null,
						rate: equipment.budget_items_rate || 0,
						uom: equipment.budget_items_uom || null,
						budget_type: equipment.budget_type || 3,
						tax_value: null,
						tax_amount: null,
						sub_total: equipment.budget_items_quantity*equipment.budget_items_rate || 0,
						total: equipment.budget_items_sub_total*equipment.budget_items_rate || 0,
					};
				}
			});

			if(equipmentsLineItem.length==0){
				this.$store.commit(SET_ERROR, [{ model: true, message: 'Products required' }]);
				return false;
			}

			this.pageLoading = true;
			try {
				//const data = 
				await createEquipment(this.$route.params.uuid, 1, equipmentsLineItem);
				await this.getLineItem()
				//console.log(data);
				this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Budget Products are saved." },
				]);
			} catch (error) {
				console.log(error);
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		changeClicked() {
			this.isOutside = true;
		},
		updateItemOrder() {
			// const old_index = param.move.oldIndex;
			// const new_index = param.move.newIndex;
			// console.log(param);
			// console.log(this.line_items);
		},
		editRow(event, index) {
			this.isOutside = false;
			const element = event.target;
			//console.log(element.nodeName);
			const _line_items = this.line_items.map((row) => {
				return { ...row, isEditable: false };
			});
			this.line_items = _line_items;
			this.line_items[index].isEditable = true;
			this.$nextTick(() => {
				const inputarea = element.querySelector("input");
				if (inputarea) {
					inputarea.focus();
					inputarea.select();
				}
			});
			if (element.nodeName == "INPUT") {
				element.select();
			}
			if (this.line_items.length == index + 1) {
				this.addLineItems();
			}
		},
		tabClicked(event, index) {
			/* console.log({ index: index, event: event }); */
			if (this.line_items.length == index + 1) {
				this.addLineItems();
			}

			const _line_items = this.line_items.map((row) => {
				return { ...row, isEditable: false };
			});
			this.line_items = _line_items;
			this.line_items[index + 1].isEditable = true;
			this.$nextTick(() => {
				// const inputarea = element.querySelector("input");
				// if (inputarea) {
				// 	inputarea.focus();
				// 	inputarea.select();
				// }
			});
		},
		async yesDeleteLineItem() {
			try {
				this.deleteConfirmationLoading = true;
				if (this.deleteId) {
					await DeletePOLineItem(this.deleteId);
					this.line_items.splice(this.deleteIndex, 1);
					this.$emit("input", this.line_items);

					this.deleteId = null;
					this.deleteIndex = -1;
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.deleteConfirmationLoading = false;
				this.deleteConfirmationDialog = false;
			}
		},
		async deleteLineItems(index, id) {
			if (id) {
				this.deleteConfirmationDialog = true;
				this.deleteId = id;
				this.deleteIndex = index;
			} else {
				if (this.line_items.length > 1) {
					this.line_items.splice(index, 1);
					this.$emit("input", this.line_items);
				}
				if (id) {
					await DeletePOLineItem(id);
				}
			}
			
		},
		addLineItems() {
			const _order = this.line_items.length;
			this.line_items.push({
				isEditable: false,
				id: null,
				budget_items_description: null,
				budget_items_uom: "lot",
				budget_type: 3,
				budget_items_quantity: 1,
				budget_items_rate: 0,
				budget_items_total: 0,
				uuid: null,
				order: _order + 1,
			});
			this.$emit("input", this.line_items);
		},

		getProductName(id) {
			const product = find(this.productList, (row) => row.value == id);
			if (product) {
				return product.text;
			} else {
				return null;
			}
		},
		getBudgetName(id) {
			
			const product = find(this.typeList, (row) => row.value == id);
			if (product) {
				return product.text;
			} else {
				return null;
			}
		},

		getAllProduct() {
			GetAllProduct()
				.then(({ list }) => {
					
					//this.productList = list.filter((row) => row.product_type == 'inventory');
					
					const ListProd =list.filter((row) => row.product_type == 'inventory');
					this.productList = ListProd.map((row1) => {
						return {
							...row1,
							value: row1.id || null,
							text: `${row1.barcode} - ${row1.name}` 
						};
					});

					// this.productList = map(list, (row) => {
						
					// 	return { ...row, value: row.id, text: `${row.barcode} - ${row.name}` };
					// });
				})
				.catch((error) => {
					console.log(error);
				});
		},

		async getLineItem() {
			const data = await GetProjectBudgetLineItems(this.$route.params.uuid, 1);
			this.line_items = data;
			
			if (!this.line_items.length) {
				this.line_items = [
					{
						isEditable: false,
						id: null,
						budget_items_description: null,
						budget_items_uom: "lot",
						budget_type: 3,
						budget_items_quantity: 1,
						budget_items_rate: 0,
						budget_items_total: 0,
						uuid: null,
					},
				];
			}
		},
	},

	components: {
		Draggable,
		// TextInput,
		AutoCompleteInput,
		SelectUnit,
		Dialog,
		QuantityInput,
		PriceInput,
	},

	async mounted() {
		await this.getLineItem();
		this.getAllProduct();
	},
};
</script>

<style scoped>
.quotation-line-items th {
	font-weight: 500;
}

.quotation-line-items th,
.quotation-line-items td:not(.borer-none) {
	border-bottom: 1px solid #c7c7c7;
	border-left: 1px solid #c7c7c7;
	height: 40px;
}

.quotation-line-items th:first-child,
.quotation-line-items td:first-child {
	border-left: none !important;
}
.quotation-line-items {
	max-height: 500px;
	overflow: auto;
}

.quotation-line-items thead {
	position: sticky;
	top: 0;
	bottom: 0;
	z-index: 999999;
	background: #f6f6f6;
}
</style>
