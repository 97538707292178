<template>
	<div>
		<v-layout class="min-height-57px">
			<v-flex class="font-level-3-bold my-auto d-flex justify-space-between w-100">
				<span class="detail-svg-icon mr-2 d-flex align-center">
					<span class="ml-2">Budget </span>
				</span>
			</v-flex>
		</v-layout>
		<v-tabs
			v-model="vTab"
			background-color="transparent"
			color="blue"
			class="custom-tab-transparent"
			active-class="blue darken-4 text-white"
			hide-slider
		>
			<v-tab href="#product">
				<v-icon small left>mdi-format-list-bulleted-square</v-icon> Product</v-tab
			>
			<v-tab href="#equipment">
				<v-icon small left>mdi-format-list-bulleted-square</v-icon> Equipment</v-tab
			>

			<v-tab href="#service">
				<v-icon small left>mdi-format-list-bulleted-square</v-icon> Service</v-tab
			>

			<v-tabs-items v-model="vTab">
				<v-tab-item value="product" v-if="vTab === 'product'">
					<div
						v-if="!pageLoading"
						class="overflow-y taskListing"
						style="max-height: calc(100vh - 420px); min-height: 350px"
					>
						<ProjectProductLineItems
							ref="poLineItemsRef"
							v-model="poLineItems"
							:supplier="supplier.id"
							:calculations="line_items_calculations"
							@update:total="getCalculation($event)"
							:projectStatus="projectStatus"
						></ProjectProductLineItems>
					</div>
					<div v-else class="mx-20 my-40 text-center">
						<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
					</div>
				</v-tab-item>
				<v-tab-item value="equipment" v-if="vTab === 'equipment'">
					<div
						v-if="!pageLoading"
						class="overflow-y taskListing"
						style="max-height: calc(100vh - 420px); min-height: 350px"
					>
						<ProjectEquipmentLineItems
						:projectStatus="projectStatus"
						></ProjectEquipmentLineItems>
					</div>
					<div v-else class="mx-20 my-40 text-center">
						<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
					</div>
				</v-tab-item>
				<v-tab-item value="service" v-if="vTab === 'service'">
					<div
						v-if="!pageLoading"
					class="custom-table-height"
					>
						<ProjectServiceLineItems
						ref="poLineItemsRef"
						v-model="poLineItemsService"
						:supplier="supplier.id"
						:calculations="line_items_calculations"
						@update:total="getCalculation($event)"
						:projectStatus="projectStatus"
						></ProjectServiceLineItems>
					</div>
					<div v-else class="mx-20 my-40 text-center">
						<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
					</div>
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>

		<!-- <DeleteTemplate
			v-if="deleteDialog"
			type="Quotation"
			v-on:success="deleteQuotation"
			v-on:close="(deleteDialog = false), (uuid = null)"
			:delete-dialog="deleteDialog"
			:deleteText="singleQuotation.title"
			:deleteByUrl="false"
		>
		</DeleteTemplate> -->
	</div>
</template>
<script>
// import ShowValue from "@/view/components/ShowValue.vue";

// import DeleteTemplate from "@/view/components/DeleteTemplate.vue";
// import Chip from "@/view/components/Chip";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { getPurchaseOrderByProject, deleteQuotation } from "@/core/lib/project.lib";
// import TableDateTime from "@/view/components/TableDateTime";
import ProjectProductLineItems from "@/view/components/ProjectProductLineItems";
import ProjectEquipmentLineItems from "@/view/components/ProjectEquipmentLineItems";
import ProjectServiceLineItems from "@/view/components/ProjectServiceLineItems";
import "@/core/event-bus/click-outside";

// import ShowPrice from "@/view/components/ShowPrice.vue";
export default {
	name: "asset-internal-listing",
	title: "Listing Internal Asset",
	components: {
		// ShowValue,
		// Chip,
		// DeleteTemplate,
		ProjectProductLineItems,
		ProjectEquipmentLineItems,
		ProjectServiceLineItems,
		// TableDateTime,
		// ShowPrice,
	},
	data() {
		return {
			deleteDialog: false,
			pageLoading: false,
			vTab: "",
			line_items_calculations: {},
			quotation_analysis: null,
			uuid: null,
			supplier: {
				id: 2,
			},
			poLineItems: [
				{
					isEditable: false,
					id: null,
					uuid: null,
					product: 1,
					description: "description",
					uom: "in",
					quantity: 1,
					rate: 140,
					total: 140,
					order: 1,
				}
			],
			poLineItemsService: [
				{
					isEditable: false,
					id: null,
					uuid: null,
					product: 1,
					description: "description",
					uom: "in",
					quantity: 1,
					rate: 140,
					total: 140,
					order: 1,
				}
			],
			singleQuotation: null,
			purchaseOrderListing: [],
			showing_string: "",
			current_page: 1,
			total_page: "",
		};
	},
	props: {
		projectStatus:{
			type: Number,
			default: 1,
		},
		relationTypeId: {
			type: Number,
			default: 0,
		},
		project: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		getCalculation(params) {
			let obj = params;
			for (const property in obj) {
				obj[property] = Number(obj[property]);
			}
			this.line_items_calculations = obj;
		},
		openDeleteConfirmationDialog(row) {
			this.deleteDialog = true;
			this.singleQuotation = row;
			this.uuid = row.uuid;
		},
		async deleteQuotation() {
			this.deleteDialog = false;
			try {
				this.pageLoading = true;
				await deleteQuotation(this.uuid);
				this.uuid = null;
				this.$store.commit(SET_MESSAGE, [
					{ model: true, message: `Success ! Quotation has been deleted.` },
				]);
				await this.getQuotationList();
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},

		goToQuotationCreate() {
			this.$router
				.push({
					name: "quotation-create",
					query: { project: this.$props.project.id, customer: this.$props.project.contact },
				})
				.catch(() => {});
		},
		goToPODetail(row) {
			this.$router.push({ name: "purchase-order-detail", params: { uuid: row.uuid } });
		},
		goToQuotationDetail(quotation) {
			this.$router
				.push({
					name: "quotation-detail",
					params: { uuid: quotation.uuid },
				})
				.catch(() => {});
		},
		async getQuotationList() {
			try {
				this.pageLoading = true;
				const { tbody, total_page, showing_string, current_page } = await getPurchaseOrderByProject(
					this.$route.params.uuid,
					this.current_page
				);
				this.purchaseOrderListing = tbody;
				this.total_page = total_page;
				this.current_page = current_page;
				this.showing_string = showing_string;
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
	},
	async mounted() {
		await this.getQuotationList();
		this.poLineItemsService = this.poLineItems.map((elem) => {
			//this.poLineItems.some((elem) => {
				if(elem.budget_type==2){
					return { ...elem};
				}
			//});
			
		});
	},
};
</script>

<style>
.menuable__content__active{
	z-index: 9999999!important;
}
</style>